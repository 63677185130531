export const COUNTRIES_LIST = [
  {
    name: {
      en: "Saudi Arabia",
      ar: "السعودية",
    },
    iso2: "SA",
    iso3: "SAU",
    currency: {
      SAR: {
        name: "Saudi riyal",
        symbol: "ر.س",
      },
    },
    country_code: "+966",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/sa.png",
      svg: "https://flagcdn.com/sa.svg",
      alt: "The flag of Saudi Arabia has a green field, at the center of which is an Arabic inscription — the Shahada — in white above a white horizontal sabre with its tip pointed to the hoist side of the field.",
    },
  },
  {
    name: {
      en: "Afghanistan",
      ar: "أفغانستان",
    },
    iso2: "AF",
    iso3: "AFG",
    currency: {
      AFN: {
        name: "Afghan afghani",
        symbol: "؋",
      },
    },
    country_code: "+93",
    region: "Asia",
    subregion: "Southern Asia",
    flags: {
      png: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png",
      svg: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg",
      alt: "The flag of the Islamic Emirate of Afghanistan has a white field with Arabic inscriptions — the Shahada — in black across its center.",
    },
  },
  {
    name: {
      en: "Albania",
      ar: "ألبانيا",
    },
    iso2: "AL",
    iso3: "ALB",
    currency: {
      ALL: {
        name: "Albanian lek",
        symbol: "L",
      },
    },
    country_code: "+355",
    region: "Europe",
    subregion: "Southeast Europe",
    flags: {
      png: "https://flagcdn.com/w320/al.png",
      svg: "https://flagcdn.com/al.svg",
      alt: "The flag of Albania features a silhouetted double-headed black eagle at the center of a red field.",
    },
  },
  {
    name: {
      en: "Algeria",
      ar: "الجزائر",
    },
    iso2: "DZ",
    iso3: "DZA",
    currency: {
      DZD: {
        name: "Algerian dinar",
        symbol: "د.ج",
      },
    },
    country_code: "+213",
    region: "Africa",
    subregion: "Northern Africa",
    flags: {
      png: "https://flagcdn.com/w320/dz.png",
      svg: "https://flagcdn.com/dz.svg",
      alt: "The flag of Algeria features two equal vertical bands of green and white. A five-pointed red star within a fly-side facing red crescent is centered over the two-color boundary.",
    },
  },
  {
    name: {
      en: "American Samoa",
      ar: "ساموا الأمريكية",
    },
    iso2: "AS",
    iso3: "ASM",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+1684",
    region: "Oceania",
    subregion: "Polynesia",
    flags: {
      png: "https://flagcdn.com/w320/as.png",
      svg: "https://flagcdn.com/as.svg",
    },
  },
  {
    name: {
      en: "Andorra",
      ar: "أندورا",
    },
    iso2: "AD",
    iso3: "AND",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+376",
    region: "Europe",
    subregion: "Southern Europe",
    flags: {
      png: "https://flagcdn.com/w320/ad.png",
      svg: "https://flagcdn.com/ad.svg",
      alt: "The flag of Andorra features three equal vertical bands of blue, yellow and red, with the coat of arms of Andorra centered in the yellow band.",
    },
  },
  {
    name: {
      en: "Angola",
      ar: "جمهورية أنغولا",
    },
    iso2: "AO",
    iso3: "AGO",
    currency: {
      AOA: {
        name: "Angolan kwanza",
        symbol: "Kz",
      },
    },
    country_code: "+244",
    region: "Africa",
    subregion: "Middle Africa",
    flags: {
      png: "https://flagcdn.com/w320/ao.png",
      svg: "https://flagcdn.com/ao.svg",
      alt: "The flag of Angola features two equal horizontal bands of red and black, with a yellow emblem at its centre. This emblem consists of a five-pointed star within the hoist-side facing half of a cogwheel that is crossed on its lower end by a machete.",
    },
  },
  {
    name: {
      en: "Anguilla",
      ar: "أنغويلا",
    },
    iso2: "AI",
    iso3: "AIA",
    currency: {
      XCD: {
        name: "Eastern Caribbean dollar",
        symbol: "$",
      },
    },
    country_code: "+1264",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/ai.png",
      svg: "https://flagcdn.com/ai.svg",
    },
  },
  {
    name: {
      en: "Antigua and Barbuda",
      ar: "أنتيغوا وباربودا",
    },
    iso2: "AG",
    iso3: "ATG",
    currency: {
      XCD: {
        name: "Eastern Caribbean dollar",
        symbol: "$",
      },
    },
    country_code: "+1268",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/ag.png",
      svg: "https://flagcdn.com/ag.svg",
      alt: "The flag of Antigua and Barbuda has a red field with an inverted isosceles triangle based on the top edge and spanning the height of the field. This triangle has three horizontal bands of black, light blue and white, with the light blue band half the height of the two other bands. The top half of a golden-yellow sun is situated in the lower two-third of the black band to depict a rising sun.",
    },
  },
  {
    name: {
      en: "Argentina",
      ar: "الأرجنتين",
    },
    iso2: "AR",
    iso3: "ARG",
    currency: {
      ARS: {
        name: "Argentine peso",
        symbol: "$",
      },
    },
    country_code: "+54",
    region: "Americas",
    subregion: "South America",
    flags: {
      png: "https://flagcdn.com/w320/ar.png",
      svg: "https://flagcdn.com/ar.svg",
      alt: "The flag of Argentina features three equal horizontal bands of light blue, white and light blue. A brown-edged golden sun is centered in the white band.",
    },
  },
  {
    name: {
      en: "Armenia",
      ar: "أرمينيا",
    },
    iso2: "AM",
    iso3: "ARM",
    currency: {
      AMD: {
        name: "Armenian dram",
        symbol: "֏",
      },
    },
    country_code: "+374",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/am.png",
      svg: "https://flagcdn.com/am.svg",
      alt: "The flag of Armenia is composed of three equal horizontal bands of red, blue and orange.",
    },
  },
  {
    name: {
      en: "Aruba",
      ar: "أروبا",
    },
    iso2: "AW",
    iso3: "ABW",
    currency: {
      AWG: {
        name: "Aruban florin",
        symbol: "ƒ",
      },
    },
    country_code: "+297",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/aw.png",
      svg: "https://flagcdn.com/aw.svg",
    },
  },
  {
    name: {
      en: "Australia",
      ar: "أستراليا",
    },
    iso2: "AU",
    iso3: "AUS",
    currency: {
      AUD: {
        name: "Australian dollar",
        symbol: "$",
      },
    },
    country_code: "+61",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    flags: {
      png: "https://flagcdn.com/w320/au.png",
      svg: "https://flagcdn.com/au.svg",
      alt: "The flag of Australia has a dark blue field. It features the flag of the United Kingdom — the Union Jack — in the canton, beneath which is a large white seven-pointed star. A representation of the Southern Cross constellation, made up of one small five-pointed and four larger seven-pointed white stars, is situated on the fly side of the field.",
    },
  },
  {
    name: {
      en: "Austria",
      ar: "النمسا",
    },
    iso2: "AT",
    iso3: "AUT",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+43",
    region: "Europe",
    subregion: "Central Europe",
    flags: {
      png: "https://flagcdn.com/w320/at.png",
      svg: "https://flagcdn.com/at.svg",
      alt: "The flag of Austria is composed of three equal horizontal bands of red, white and red.",
    },
  },
  {
    name: {
      en: "Azerbaijan",
      ar: "أذربيجان",
    },
    iso2: "AZ",
    iso3: "AZE",
    currency: {
      AZN: {
        name: "Azerbaijani manat",
        symbol: "₼",
      },
    },
    country_code: "+994",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/az.png",
      svg: "https://flagcdn.com/az.svg",
      alt: "The flag of Azerbaijan features three equal horizontal bands of blue, red and green, with a white fly-side facing crescent and eight-pointed star centered in the red band.",
    },
  },
  {
    name: {
      en: "Bahamas",
      ar: "باهاماس",
    },
    iso2: "BS",
    iso3: "BHS",
    currency: {
      BSD: {
        name: "Bahamian dollar",
        symbol: "$",
      },
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+1242",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/bs.png",
      svg: "https://flagcdn.com/bs.svg",
      alt: "The flag of the Bahamas is composed of three equal horizontal bands of aquamarine, yellow and aquamarine, with a black equilateral triangle superimposed on the hoist side of the field. This triangle has its base on the hoist end and spans about one-third the width of the field.",
    },
  },
  {
    name: {
      en: "Bahrain",
      ar: "‏البحرين",
    },
    iso2: "BH",
    iso3: "BHR",
    currency: {
      BHD: {
        name: "Bahraini dinar",
        symbol: ".د.ب",
      },
    },
    country_code: "+973",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/bh.png",
      svg: "https://flagcdn.com/bh.svg",
      alt: "The flag of Bahrain has a red field. On the hoist side, it features a white vertical band that spans about one-third the width of the field and is separated from the rest of the field by five adjoining fly-side pointing white isosceles triangles that serve as a serrated line.",
    },
  },
  {
    name: {
      en: "Bangladesh",
      ar: "بنغلاديش",
    },
    iso2: "BD",
    iso3: "BGD",
    currency: {
      BDT: {
        name: "Bangladeshi taka",
        symbol: "৳",
      },
    },
    country_code: "+880",
    region: "Asia",
    subregion: "Southern Asia",
    flags: {
      png: "https://flagcdn.com/w320/bd.png",
      svg: "https://flagcdn.com/bd.svg",
      alt: "The flag of Bangladesh has a dark green field bearing a large red circle that is offset slightly towards the hoist side of center.",
    },
  },
  {
    name: {
      en: "Barbados",
      ar: "باربادوس",
    },
    iso2: "BB",
    iso3: "BRB",
    currency: {
      BBD: {
        name: "Barbadian dollar",
        symbol: "$",
      },
    },
    country_code: "+1246",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/bb.png",
      svg: "https://flagcdn.com/bb.svg",
      alt: "The flag of Barbados is composed of three equal vertical bands of ultramarine, gold and ultramarine. The head of a black trident is centered in the gold band.",
    },
  },
  {
    name: {
      en: "Belarus",
      ar: "بيلاروسيا",
    },
    iso2: "BY",
    iso3: "BLR",
    currency: {
      BYN: {
        name: "Belarusian ruble",
        symbol: "Br",
      },
    },
    country_code: "+375",
    region: "Europe",
    subregion: "Eastern Europe",
    flags: {
      png: "https://flagcdn.com/w320/by.png",
      svg: "https://flagcdn.com/by.svg",
      alt: "The flag of Belarus features a vertical band, with a white and red ornamental pattern, spanning about one-fifth the width of the field on the hoist side. Adjoining the vertical band are two horizontal bands of red and green, with the red band twice the height of the green band.",
    },
  },
  {
    name: {
      en: "Belgium",
      ar: "بلجيكا",
    },
    iso2: "BE",
    iso3: "BEL",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+32",
    region: "Europe",
    subregion: "Western Europe",
    flags: {
      png: "https://flagcdn.com/w320/be.png",
      svg: "https://flagcdn.com/be.svg",
      alt: "The flag of Belgium is composed of three equal vertical bands of black, yellow and red.",
    },
  },
  {
    name: {
      en: "Belize",
      ar: "بليز",
    },
    iso2: "BZ",
    iso3: "BLZ",
    currency: {
      BZD: {
        name: "Belize dollar",
        symbol: "$",
      },
    },
    country_code: "+501",
    region: "Americas",
    subregion: "Central America",
    flags: {
      png: "https://flagcdn.com/w320/bz.png",
      svg: "https://flagcdn.com/bz.svg",
      alt: "The flag of Belize has a royal blue field with a thin red horizontal band at the top and bottom of the field and the national coat of arms in the center.",
    },
  },
  {
    name: {
      en: "Benin",
      ar: "بنين",
    },
    iso2: "BJ",
    iso3: "BEN",
    currency: {
      XOF: {
        name: "West African CFA franc",
        symbol: "Fr",
      },
    },
    country_code: "+229",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/bj.png",
      svg: "https://flagcdn.com/bj.svg",
      alt: "The flag of Benin features a green vertical band on its hoist side that takes up about two-fifth the width of the field and two equal horizontal bands of yellow and red adjoining the vertical band.",
    },
  },
  {
    name: {
      en: "Bermuda",
      ar: "برمودا",
    },
    iso2: "BM",
    iso3: "BMU",
    currency: {
      BMD: {
        name: "Bermudian dollar",
        symbol: "$",
      },
    },
    country_code: "+1441",
    region: "Americas",
    subregion: "North America",
    flags: {
      png: "https://flagcdn.com/w320/bm.png",
      svg: "https://flagcdn.com/bm.svg",
    },
  },
  {
    name: {
      en: "Bhutan",
      ar: "بوتان",
    },
    iso2: "BT",
    iso3: "BTN",
    currency: {
      BTN: {
        name: "Bhutanese ngultrum",
        symbol: "Nu.",
      },
      INR: {
        name: "Indian rupee",
        symbol: "₹",
      },
    },
    country_code: "+975",
    region: "Asia",
    subregion: "Southern Asia",
    flags: {
      png: "https://flagcdn.com/w320/bt.png",
      svg: "https://flagcdn.com/bt.svg",
      alt: "The flag of Bhutan is divided diagonally, from the lower hoist-side corner to the upper fly-side corner, into an upper yellow and a lower orange triangle. A fly-side facing white dragon holding four jewels in its claws is situated along the boundary of the two triangles.",
    },
  },
  {
    name: {
      en: "Bolivia",
      ar: "بوليفيا",
    },
    iso2: "BO",
    iso3: "BOL",
    currency: {
      BOB: {
        name: "Bolivian boliviano",
        symbol: "Bs.",
      },
    },
    country_code: "+591",
    region: "Americas",
    subregion: "South America",
    flags: {
      png: "https://flagcdn.com/w320/bo.png",
      svg: "https://flagcdn.com/bo.svg",
      alt: "The flag of Bolivia is composed of three equal horizontal bands of red, yellow and green, with the national coat of arms centered in the yellow band.",
    },
  },
  {
    name: {
      en: "Bosnia and Herzegovina",
      ar: "البوسنة والهرسك",
    },
    iso2: "BA",
    iso3: "BIH",
    currency: {
      BAM: {
        name: "Bosnia and Herzegovina convertible mark",
      },
    },
    country_code: "+387",
    region: "Europe",
    subregion: "Southeast Europe",
    flags: {
      png: "https://flagcdn.com/w320/ba.png",
      svg: "https://flagcdn.com/ba.svg",
      alt: "The flag of Bosnia and Herzegovina has a blue field, at the center of which is a large yellow hoist-side facing right-angled triangle that is based on the top edge and spans the height of the field. Adjacent to the hypotenuse of this triangle are nine adjoining five-pointed white stars with the top and bottom stars cut in half by the edges of the field.",
    },
  },
  {
    name: {
      en: "Botswana",
      ar: "بوتسوانا",
    },
    iso2: "BW",
    iso3: "BWA",
    currency: {
      BWP: {
        name: "Botswana pula",
        symbol: "P",
      },
    },
    country_code: "+267",
    region: "Africa",
    subregion: "Southern Africa",
    flags: {
      png: "https://flagcdn.com/w320/bw.png",
      svg: "https://flagcdn.com/bw.svg",
      alt: "The flag of Botswana has a light blue field with a white-edged black horizontal band across its center.",
    },
  },
  {
    name: {
      en: "Bouvet Island",
      ar: "جزر بوفيه",
    },
    iso2: "BV",
    iso3: "BVT",
    country_code: "+47",
    region: "Antarctic",
    flags: {
      png: "https://flagcdn.com/w320/bv.png",
      svg: "https://flagcdn.com/bv.svg",
    },
  },
  {
    name: {
      en: "Brazil",
      ar: "البرازيل",
    },
    iso2: "BR",
    iso3: "BRA",
    currency: {
      BRL: {
        name: "Brazilian real",
        symbol: "R$",
      },
    },
    country_code: "+55",
    region: "Americas",
    subregion: "South America",
    flags: {
      png: "https://flagcdn.com/w320/br.png",
      svg: "https://flagcdn.com/br.svg",
      alt: "The flag of Brazil has a green field with a large yellow rhombus in the center. Within the rhombus is a dark blue globe with twenty-seven small five-pointed white stars depicting a starry sky and a thin white convex horizontal band inscribed with the national motto 'Ordem e Progresso' across its center.",
    },
  },
  {
    name: {
      en: "British Indian Ocean Territory",
      ar: "إقليم المحيط الهندي البريطاني",
    },
    iso2: "IO",
    iso3: "IOT",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+246",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/io.png",
      svg: "https://flagcdn.com/io.svg",
    },
  },
  {
    name: {
      en: "British Virgin Islands",
      ar: "جزر العذراء",
    },
    iso2: "VG",
    iso3: "VGB",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+1284",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/vg.png",
      svg: "https://flagcdn.com/vg.svg",
    },
  },
  {
    name: {
      en: "Brunei",
      ar: "بروناي",
    },
    iso2: "BN",
    iso3: "BRN",
    currency: {
      BND: {
        name: "Brunei dollar",
        symbol: "$",
      },
      SGD: {
        name: "Singapore dollar",
        symbol: "$",
      },
    },
    country_code: "+673",
    region: "Asia",
    subregion: "South-Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/bn.png",
      svg: "https://flagcdn.com/bn.svg",
      alt: "The flag of Brunei has a yellow field with two adjoining diagonal bands of white and black that extend from the upper hoist side of the field to the lower fly side. The red emblem of Brunei is centered on the field.",
    },
  },
  {
    name: {
      en: "Bulgaria",
      ar: "بلغاريا",
    },
    iso2: "BG",
    iso3: "BGR",
    currency: {
      BGN: {
        name: "Bulgarian lev",
        symbol: "лв",
      },
    },
    country_code: "+359",
    region: "Europe",
    subregion: "Southeast Europe",
    flags: {
      png: "https://flagcdn.com/w320/bg.png",
      svg: "https://flagcdn.com/bg.svg",
      alt: "The flag of Bulgaria is composed of three equal horizontal bands of white, green and red.",
    },
  },
  {
    name: {
      en: "Burkina Faso",
      ar: "بوركينا فاسو",
    },
    iso2: "BF",
    iso3: "BFA",
    currency: {
      XOF: {
        name: "West African CFA franc",
        symbol: "Fr",
      },
    },
    country_code: "+226",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/bf.png",
      svg: "https://flagcdn.com/bf.svg",
      alt: "The flag of Burkina Faso features two equal horizontal bands of red and green, with a yellow five-pointed star in the center.",
    },
  },
  {
    name: {
      en: "Burundi",
      ar: "بوروندي",
    },
    iso2: "BI",
    iso3: "BDI",
    currency: {
      BIF: {
        name: "Burundian franc",
        symbol: "Fr",
      },
    },
    country_code: "+257",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/bi.png",
      svg: "https://flagcdn.com/bi.svg",
      alt: "The flag of Burundi is divided by a white diagonal cross into four alternating triangular areas of red at the top and bottom, and green on the hoist and fly sides. A white circle, with three green-edged red six-pointed stars arranged to form a triangle, is superimposed at the center of the cross.",
    },
  },
  {
    name: {
      en: "Cambodia",
      ar: "كمبوديا",
    },
    iso2: "KH",
    iso3: "KHM",
    currency: {
      KHR: {
        name: "Cambodian riel",
        symbol: "៛",
      },
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+855",
    region: "Asia",
    subregion: "South-Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/kh.png",
      svg: "https://flagcdn.com/kh.svg",
      alt: "The flag of Cambodia features three horizontal bands of blue, red and blue, with a white depiction of the temple complex, Angkor Wat centered in the red band.",
    },
  },
  {
    name: {
      en: "Cameroon",
      ar: "الكاميرون",
    },
    iso2: "CM",
    iso3: "CMR",
    currency: {
      XAF: {
        name: "Central African CFA franc",
        symbol: "Fr",
      },
    },
    country_code: "+237",
    region: "Africa",
    subregion: "Middle Africa",
    flags: {
      png: "https://flagcdn.com/w320/cm.png",
      svg: "https://flagcdn.com/cm.svg",
      alt: "The flag of Cameroon is composed of three equal vertical bands of green, red and yellow, with a yellow five-pointed star in the center.",
    },
  },
  {
    name: {
      en: "Canada",
      ar: "كندا",
    },
    iso2: "CA",
    iso3: "CAN",
    currency: {
      CAD: {
        name: "Canadian dollar",
        symbol: "$",
      },
    },
    country_code: "+1",
    region: "Americas",
    subregion: "North America",
    flags: {
      png: "https://flagcdn.com/w320/ca.png",
      svg: "https://flagcdn.com/ca.svg",
      alt: "The flag of Canada is composed of a red vertical band on the hoist and fly sides and a central white square that is twice the width of the vertical bands. A large eleven-pointed red maple leaf is centered in the white square.",
    },
  },
  {
    name: {
      en: "Cape Verde",
      ar: "كابو فيردي",
    },
    iso2: "CV",
    iso3: "CPV",
    currency: {
      CVE: {
        name: "Cape Verdean escudo",
        symbol: "Esc",
      },
    },
    country_code: "+238",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/cv.png",
      svg: "https://flagcdn.com/cv.svg",
      alt: "The flag of Cape Verde is composed of five horizontal bands of blue, white, red, white and blue in the ratio of 6:1:1:1:3. A ring of ten five-pointed yellow stars is centered at three-eighth of the height from the bottom edge and three-eighth of the width from the hoist end of the field.",
    },
  },
  {
    name: {
      en: "Caribbean Netherlands",
      ar: "الجزر الكاريبية الهولندية",
    },
    iso2: "BQ",
    iso3: "BES",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+599",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/bq.png",
      svg: "https://flagcdn.com/bq.svg",
    },
  },
  {
    name: {
      en: "Cayman Islands",
      ar: "جزر كايمان",
    },
    iso2: "KY",
    iso3: "CYM",
    currency: {
      KYD: {
        name: "Cayman Islands dollar",
        symbol: "$",
      },
    },
    country_code: "+1345",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/ky.png",
      svg: "https://flagcdn.com/ky.svg",
    },
  },
  {
    name: {
      en: "Central African Republic",
      ar: "جمهورية أفريقيا الوسطى",
    },
    iso2: "CF",
    iso3: "CAF",
    currency: {
      XAF: {
        name: "Central African CFA franc",
        symbol: "Fr",
      },
    },
    country_code: "+236",
    region: "Africa",
    subregion: "Middle Africa",
    flags: {
      png: "https://flagcdn.com/w320/cf.png",
      svg: "https://flagcdn.com/cf.svg",
      alt: "The flag of Central African Republic is composed of four equal horizontal bands of blue, white, green and yellow intersected at the center by a vertical red band of equal size as the horizontal bands. A yellow five-pointed star is situated on the hoist side of the blue band.",
    },
  },
  {
    name: {
      en: "Chad",
      ar: "تشاد",
    },
    iso2: "TD",
    iso3: "TCD",
    currency: {
      XAF: {
        name: "Central African CFA franc",
        symbol: "Fr",
      },
    },
    country_code: "+235",
    region: "Africa",
    subregion: "Middle Africa",
    flags: {
      png: "https://flagcdn.com/w320/td.png",
      svg: "https://flagcdn.com/td.svg",
      alt: "The flag of Chad is composed of three equal vertical bands of blue, gold and red.",
    },
  },
  {
    name: {
      en: "Chile",
      ar: "تشيلي",
    },
    iso2: "CL",
    iso3: "CHL",
    currency: {
      CLP: {
        name: "Chilean peso",
        symbol: "$",
      },
    },
    country_code: "+56",
    region: "Americas",
    subregion: "South America",
    flags: {
      png: "https://flagcdn.com/w320/cl.png",
      svg: "https://flagcdn.com/cl.svg",
      alt: "The flag of Chile is composed of two equal horizontal bands of white and red, with a blue square of the same height as the white band superimposed in the canton. A white five-pointed star is centered in the blue square.",
    },
  },
  {
    name: {
      en: "China",
      ar: "الصين",
    },
    iso2: "CN",
    iso3: "CHN",
    currency: {
      CNY: {
        name: "Chinese yuan",
        symbol: "¥",
      },
    },
    country_code: "+86",
    region: "Asia",
    subregion: "Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/cn.png",
      svg: "https://flagcdn.com/cn.svg",
      alt: "The flag of China has a red field. In the canton are five yellow five-pointed stars — a large star and four smaller stars arranged in a vertical arc on the fly side of the large star.",
    },
  },
  {
    name: {
      en: "Christmas Island",
      ar: "جزيرة كريسماس",
    },
    iso2: "CX",
    iso3: "CXR",
    currency: {
      AUD: {
        name: "Australian dollar",
        symbol: "$",
      },
    },
    country_code: "+61",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    flags: {
      png: "https://flagcdn.com/w320/cx.png",
      svg: "https://flagcdn.com/cx.svg",
    },
  },
  {
    name: {
      en: "Cocos (Keeling) Islands",
      ar: "جزر كوكوس",
    },
    iso2: "CC",
    iso3: "CCK",
    currency: {
      AUD: {
        name: "Australian dollar",
        symbol: "$",
      },
    },
    country_code: "+61",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    flags: {
      png: "https://flagcdn.com/w320/cc.png",
      svg: "https://flagcdn.com/cc.svg",
    },
  },
  {
    name: {
      en: "Colombia",
      ar: "كولومبيا",
    },
    iso2: "CO",
    iso3: "COL",
    currency: {
      COP: {
        name: "Colombian peso",
        symbol: "$",
      },
    },
    country_code: "+57",
    region: "Americas",
    subregion: "South America",
    flags: {
      png: "https://flagcdn.com/w320/co.png",
      svg: "https://flagcdn.com/co.svg",
      alt: "The flag of Colombia is composed of three horizontal bands of yellow, blue and red, with the yellow band twice the height of the other two bands.",
    },
  },
  {
    name: {
      en: "Comoros",
      ar: "جزر القمر",
    },
    iso2: "KM",
    iso3: "COM",
    currency: {
      KMF: {
        name: "Comorian franc",
        symbol: "Fr",
      },
    },
    country_code: "+269",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/km.png",
      svg: "https://flagcdn.com/km.svg",
      alt: "The flag of Comoros is composed of four equal horizontal bands of yellow, white, red and blue, with a green isosceles triangle superimposed on the hoist side of the field. This triangle has its base on the hoist end, spans about two-fifth the width of the field and bears a fly-side facing white crescent and four five-pointed white stars arranged in a vertical line along the opening of the crescent.",
    },
  },
  {
    name: {
      en: "Cook Islands",
      ar: "جزر كوك",
    },
    iso2: "CK",
    iso3: "COK",
    currency: {
      CKD: {
        name: "Cook Islands dollar",
        symbol: "$",
      },
      NZD: {
        name: "New Zealand dollar",
        symbol: "$",
      },
    },
    country_code: "+682",
    region: "Oceania",
    subregion: "Polynesia",
    flags: {
      png: "https://flagcdn.com/w320/ck.png",
      svg: "https://flagcdn.com/ck.svg",
    },
  },
  {
    name: {
      en: "Costa Rica",
      ar: "كوستاريكا",
    },
    iso2: "CR",
    iso3: "CRI",
    currency: {
      CRC: {
        name: "Costa Rican colón",
        symbol: "₡",
      },
    },
    country_code: "+506",
    region: "Americas",
    subregion: "Central America",
    flags: {
      png: "https://flagcdn.com/w320/cr.png",
      svg: "https://flagcdn.com/cr.svg",
      alt: "The flag of Costa Rica is composed of five horizontal bands of blue, white, red, white and blue. The central red band is twice the height of the other four bands.",
    },
  },
  {
    name: {
      en: "Croatia",
      ar: "كرواتيا",
    },
    iso2: "HR",
    iso3: "HRV",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+385",
    region: "Europe",
    subregion: "Southeast Europe",
    flags: {
      png: "https://flagcdn.com/w320/hr.png",
      svg: "https://flagcdn.com/hr.svg",
      alt: "The flag of Croatia is composed of three equal horizontal bands of red, white and blue, with coat of arms of Croatia superimposed in the center.",
    },
  },
  {
    name: {
      en: "Cuba",
      ar: "كوبا",
    },
    iso2: "CU",
    iso3: "CUB",
    currency: {
      CUC: {
        name: "Cuban convertible peso",
        symbol: "$",
      },
      CUP: {
        name: "Cuban peso",
        symbol: "$",
      },
    },
    country_code: "+53",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/cu.png",
      svg: "https://flagcdn.com/cu.svg",
      alt: "The flag of Cuba is composed of five equal horizontal bands of blue alternating with white and a red equilateral triangle superimposed on the hoist side of the field. The triangle has its base on the hoist end, spans about two-fifth the width of the field and bears a white five-pointed star at its center.",
    },
  },
  {
    name: {
      en: "Curaçao",
      ar: "كوراساو",
    },
    iso2: "CW",
    iso3: "CUW",
    currency: {
      ANG: {
        name: "Netherlands Antillean guilder",
        symbol: "ƒ",
      },
    },
    country_code: "+599",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/cw.png",
      svg: "https://flagcdn.com/cw.svg",
    },
  },
  {
    name: {
      en: "Cyprus",
      ar: "قبرص",
    },
    iso2: "CY",
    iso3: "CYP",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+357",
    region: "Europe",
    subregion: "Southern Europe",
    flags: {
      png: "https://flagcdn.com/w320/cy.png",
      svg: "https://flagcdn.com/cy.svg",
      alt: "The flag of Cyprus has a white field, at the center of which is a copper-colored silhouette of the Island of Cyprus above two green olive branches crossed at the stem.",
    },
  },
  {
    name: {
      en: "Czechia",
      ar: "التشيك",
    },
    iso2: "CZ",
    iso3: "CZE",
    currency: {
      CZK: {
        name: "Czech koruna",
        symbol: "Kč",
      },
    },
    country_code: "+420",
    region: "Europe",
    subregion: "Central Europe",
    flags: {
      png: "https://flagcdn.com/w320/cz.png",
      svg: "https://flagcdn.com/cz.svg",
      alt: "The flag of Czechia is composed of two equal horizontal bands of white and red, with a blue isosceles triangle superimposed on the hoist side of the field. The triangle has its base on the hoist end and spans about two-fifth the width of the field.",
    },
  },
  {
    name: {
      en: "Denmark",
      ar: "الدنمارك",
    },
    iso2: "DK",
    iso3: "DNK",
    currency: {
      DKK: {
        name: "Danish krone",
        symbol: "kr",
      },
    },
    country_code: "+45",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/dk.png",
      svg: "https://flagcdn.com/dk.svg",
      alt: "The flag of Denmark has a red field with a large white cross that extend to the edges of the field. The vertical part of this cross is offset towards the hoist side.",
    },
  },
  {
    name: {
      en: "Djibouti",
      ar: "جيبوتي",
    },
    iso2: "DJ",
    iso3: "DJI",
    currency: {
      DJF: {
        name: "Djiboutian franc",
        symbol: "Fr",
      },
    },
    country_code: "+253",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/dj.png",
      svg: "https://flagcdn.com/dj.svg",
      alt: "The flag of Djibouti is composed of two equal horizontal bands of light blue and light green, with a white isosceles triangle superimposed on the hoist side of the field. The triangle has its base on the hoist end, spans about two-fifth the width of the field and bears a red five-pointed star at its center.",
    },
  },
  {
    name: {
      en: "Dominica",
      ar: "دومينيكا",
    },
    iso2: "DM",
    iso3: "DMA",
    currency: {
      XCD: {
        name: "Eastern Caribbean dollar",
        symbol: "$",
      },
    },
    country_code: "+1767",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/dm.png",
      svg: "https://flagcdn.com/dm.svg",
      alt: "The flag of Dominica has a green field with a large centered tricolor cross. The vertical and horizontal parts of the cross each comprise three bands of yellow, black and white. A red circle, bearing a hoist-side facing purple Sisserou parrot standing on a twig and encircled by ten five-pointed yellow-edged green stars, is superimposed at the center of the cross.",
    },
  },
  {
    name: {
      en: "Dominican Republic",
      ar: "جمهورية الدومينيكان",
    },
    iso2: "DO",
    iso3: "DOM",
    currency: {
      DOP: {
        name: "Dominican peso",
        symbol: "$",
      },
    },
    country_code: "+1809",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/do.png",
      svg: "https://flagcdn.com/do.svg",
      alt: "The flag of the Dominican Republic is divided into four rectangles by a centered white cross that extends to the edges of the field and bears the national coat of arms in its center. The upper hoist-side and lower fly-side rectangles are blue and the lower hoist-side and upper fly-side rectangles are red.",
    },
  },
  {
    name: {
      en: "DR Congo",
      ar: "الكونغو",
    },
    iso2: "CD",
    iso3: "COD",
    currency: {
      CDF: {
        name: "Congolese franc",
        symbol: "FC",
      },
    },
    country_code: "+243",
    region: "Africa",
    subregion: "Middle Africa",
    flags: {
      png: "https://flagcdn.com/w320/cd.png",
      svg: "https://flagcdn.com/cd.svg",
      alt: "The flag of the Democratic Republic of the Congo has a sky-blue field with a yellow-edged red diagonal band that extends from the lower hoist-side corner to the upper fly-side corner of the field. A large five-pointed yellow star is situated above the diagonal band on the upper hoist side of the field.",
    },
  },
  {
    name: {
      en: "Ecuador",
      ar: "الإكوادور",
    },
    iso2: "EC",
    iso3: "ECU",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+593",
    region: "Americas",
    subregion: "South America",
    flags: {
      png: "https://flagcdn.com/w320/ec.png",
      svg: "https://flagcdn.com/ec.svg",
      alt: "The flag of Ecuador is composed of the horizontal bands of yellow, blue and red, with the yellow band twice the height of the other two bands. The Ecuadorian coat of arms is superimposed in the center of the field.",
    },
  },
  {
    name: {
      en: "Egypt",
      ar: "مصر",
    },
    iso2: "EG",
    iso3: "EGY",
    currency: {
      EGP: {
        name: "Egyptian pound",
        symbol: "£",
      },
    },
    country_code: "+20",
    region: "Africa",
    subregion: "Northern Africa",
    flags: {
      png: "https://flagcdn.com/w320/eg.png",
      svg: "https://flagcdn.com/eg.svg",
      alt: "The flag of Egypt is composed of three equal horizontal bands of red, white and black, with Egypt's national emblem — a hoist-side facing gold eagle of Saladin — centered in the white band.",
    },
  },
  {
    name: {
      en: "El Salvador",
      ar: "السلفادور",
    },
    iso2: "SV",
    iso3: "SLV",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+503",
    region: "Americas",
    subregion: "Central America",
    flags: {
      png: "https://flagcdn.com/w320/sv.png",
      svg: "https://flagcdn.com/sv.svg",
      alt: "The flag of El Salvador is composed of three equal horizontal bands of cobalt blue, white and cobalt blue, with the national coat of arms centered in the white band.",
    },
  },
  {
    name: {
      en: "Equatorial Guinea",
      ar: "غينيا الاستوائية",
    },
    iso2: "GQ",
    iso3: "GNQ",
    currency: {
      XAF: {
        name: "Central African CFA franc",
        symbol: "Fr",
      },
    },
    country_code: "+240",
    region: "Africa",
    subregion: "Middle Africa",
    flags: {
      png: "https://flagcdn.com/w320/gq.png",
      svg: "https://flagcdn.com/gq.svg",
      alt: "The flag of Equatorial Guinea is composed of three equal horizontal bands of green, white and red with the national coat of arms centered in the white band and an isosceles triangle superimposed on the hoist side of the field. The triangle is light blue, has its base on the hoist end and spans about one-fifth the width of the field.",
    },
  },
  {
    name: {
      en: "Eritrea",
      ar: "إريتريا",
    },
    iso2: "ER",
    iso3: "ERI",
    currency: {
      ERN: {
        name: "Eritrean nakfa",
        symbol: "Nfk",
      },
    },
    country_code: "+291",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/er.png",
      svg: "https://flagcdn.com/er.svg",
      alt: "The flag of Eritrea comprises three triangles — a large red isosceles triangle with its base spanning the hoist end and its apex at the midpoint on the fly end, and a green and blue right-angled triangle above and beneath the red triangle. On the hoist side of the red triangle is a golden vertical olive branch encircled by a golden olive wreath.",
    },
  },
  {
    name: {
      en: "Estonia",
      ar: "إستونيا",
    },
    iso2: "EE",
    iso3: "EST",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+372",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/ee.png",
      svg: "https://flagcdn.com/ee.svg",
      alt: "The flag of Estonia is composed of three equal horizontal bands of blue, black and white.",
    },
  },
  {
    name: {
      en: "Eswatini",
      ar: "إسواتيني",
    },
    iso2: "SZ",
    iso3: "SWZ",
    currency: {
      SZL: {
        name: "Swazi lilangeni",
        symbol: "L",
      },
      ZAR: {
        name: "South African rand",
        symbol: "R",
      },
    },
    country_code: "+268",
    region: "Africa",
    subregion: "Southern Africa",
    flags: {
      png: "https://flagcdn.com/w320/sz.png",
      svg: "https://flagcdn.com/sz.svg",
      alt: "The flag of Eswatini is composed of three horizontal bands — a large central yellow-edged red band, and a light blue band above and beneath the red band. The red band is three times the height of the blue bands and bears a centered emblem made up of a large black and white Nguni shield covering two spears and a staff decorated with feather tassels, all placed horizontally.",
    },
  },
  {
    name: {
      en: "Ethiopia",
      ar: "إثيوبيا",
    },
    iso2: "ET",
    iso3: "ETH",
    currency: {
      ETB: {
        name: "Ethiopian birr",
        symbol: "Br",
      },
    },
    country_code: "+251",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/et.png",
      svg: "https://flagcdn.com/et.svg",
      alt: "The flag of Ethiopia is composed of three equal horizontal bands of green, yellow and red, with the national emblem superimposed at the center of the field. The national emblem comprises a light blue circle bearing a golden-yellow pentagram with single yellow rays emanating from the angles between the points of the pentagram.",
    },
  },
  {
    name: {
      en: "Falkland Islands",
      ar: "جزر فوكلاند",
    },
    iso2: "FK",
    iso3: "FLK",
    currency: {
      FKP: {
        name: "Falkland Islands pound",
        symbol: "£",
      },
    },
    country_code: "+500",
    region: "Americas",
    subregion: "South America",
    flags: {
      png: "https://flagcdn.com/w320/fk.png",
      svg: "https://flagcdn.com/fk.svg",
    },
  },
  {
    name: {
      en: "Faroe Islands",
      ar: "جزر فارو",
    },
    iso2: "FO",
    iso3: "FRO",
    currency: {
      DKK: {
        name: "Danish krone",
        symbol: "kr",
      },
      FOK: {
        name: "Faroese króna",
        symbol: "kr",
      },
    },
    country_code: "+298",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/fo.png",
      svg: "https://flagcdn.com/fo.svg",
    },
  },
  {
    name: {
      en: "Fiji",
      ar: "فيجي",
    },
    iso2: "FJ",
    iso3: "FJI",
    currency: {
      FJD: {
        name: "Fijian dollar",
        symbol: "$",
      },
    },
    country_code: "+679",
    region: "Oceania",
    subregion: "Melanesia",
    flags: {
      png: "https://flagcdn.com/w320/fj.png",
      svg: "https://flagcdn.com/fj.svg",
      alt: "The flag of Fiji has a light blue field. It features the flag of the United Kingdom — the Union Jack — in the canton and the shield of the national coat of arms centered in the fly half.",
    },
  },
  {
    name: {
      en: "Finland",
      ar: "فنلندا",
    },
    iso2: "FI",
    iso3: "FIN",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+358",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/fi.png",
      svg: "https://flagcdn.com/fi.svg",
      alt: "The flag of Finland has a white field with a large blue cross that extend to the edges of the field. The vertical part of this cross is offset towards the hoist side.",
    },
  },
  {
    name: {
      en: "France",
      ar: "فرنسا",
    },
    iso2: "FR",
    iso3: "FRA",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+33",
    region: "Europe",
    subregion: "Western Europe",
    flags: {
      png: "https://flagcdn.com/w320/fr.png",
      svg: "https://flagcdn.com/fr.svg",
      alt: "The flag of France is composed of three equal vertical bands of blue, white and red.",
    },
  },
  {
    name: {
      en: "French Guiana",
      ar: "غويانا",
    },
    iso2: "GF",
    iso3: "GUF",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+594",
    region: "Americas",
    subregion: "South America",
    flags: {
      png: "https://flagcdn.com/w320/gf.png",
      svg: "https://flagcdn.com/gf.svg",
    },
  },
  {
    name: {
      en: "French Polynesia",
      ar: "بولينزيا الفرنسية",
    },
    iso2: "PF",
    iso3: "PYF",
    currency: {
      XPF: {
        name: "CFP franc",
        symbol: "₣",
      },
    },
    country_code: "+689",
    region: "Oceania",
    subregion: "Polynesia",
    flags: {
      png: "https://flagcdn.com/w320/pf.png",
      svg: "https://flagcdn.com/pf.svg",
    },
  },
  {
    name: {
      en: "French Southern and Antarctic Lands",
      ar: "أراض فرنسية جنوبية وأنتارتيكية",
    },
    iso2: "TF",
    iso3: "ATF",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+262",
    region: "Antarctic",
    flags: {
      png: "https://flagcdn.com/w320/tf.png",
      svg: "https://flagcdn.com/tf.svg",
    },
  },
  {
    name: {
      en: "Gabon",
      ar: "الغابون",
    },
    iso2: "GA",
    iso3: "GAB",
    currency: {
      XAF: {
        name: "Central African CFA franc",
        symbol: "Fr",
      },
    },
    country_code: "+241",
    region: "Africa",
    subregion: "Middle Africa",
    flags: {
      png: "https://flagcdn.com/w320/ga.png",
      svg: "https://flagcdn.com/ga.svg",
      alt: "The flag of Gabon is composed of three equal horizontal bands of green, yellow and blue.",
    },
  },
  {
    name: {
      en: "Gambia",
      ar: "غامبيا",
    },
    iso2: "GM",
    iso3: "GMB",
    currency: {
      GMD: {
        name: "dalasi",
        symbol: "D",
      },
    },
    country_code: "+220",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/gm.png",
      svg: "https://flagcdn.com/gm.svg",
      alt: "The flag of Gambia is composed of three equal horizontal bands of red, blue with white top and bottom edges, and green.",
    },
  },
  {
    name: {
      en: "Georgia",
      ar: "جورجيا",
    },
    iso2: "GE",
    iso3: "GEO",
    currency: {
      GEL: {
        name: "lari",
        symbol: "₾",
      },
    },
    country_code: "+995",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/ge.png",
      svg: "https://flagcdn.com/ge.svg",
      alt: "The flag of Georgia has a white field with a large centered red cross that extends to the edges and divides the field into four quarters. A small red Bolnur-Katskhuri cross is centered in each quarter.",
    },
  },
  {
    name: {
      en: "Germany",
      ar: "ألمانيا",
    },
    iso2: "DE",
    iso3: "DEU",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+49",
    region: "Europe",
    subregion: "Western Europe",
    flags: {
      png: "https://flagcdn.com/w320/de.png",
      svg: "https://flagcdn.com/de.svg",
      alt: "The flag of Germany is composed of three equal horizontal bands of black, red and gold.",
    },
  },
  {
    name: {
      en: "Ghana",
      ar: "غانا",
    },
    iso2: "GH",
    iso3: "GHA",
    currency: {
      GHS: {
        name: "Ghanaian cedi",
        symbol: "₵",
      },
    },
    country_code: "+233",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/gh.png",
      svg: "https://flagcdn.com/gh.svg",
      alt: "The flag of Ghana is composed of three equal horizontal bands of red, gold and green, with a five-pointed black star centered in the gold band.",
    },
  },
  {
    name: {
      en: "Gibraltar",
      ar: "جبل طارق",
    },
    iso2: "GI",
    iso3: "GIB",
    currency: {
      GIP: {
        name: "Gibraltar pound",
        symbol: "£",
      },
    },
    country_code: "+350",
    region: "Europe",
    subregion: "Southern Europe",
    flags: {
      png: "https://flagcdn.com/w320/gi.png",
      svg: "https://flagcdn.com/gi.svg",
    },
  },
  {
    name: {
      en: "Greece",
      ar: "اليونان",
    },
    iso2: "GR",
    iso3: "GRC",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+30",
    region: "Europe",
    subregion: "Southern Europe",
    flags: {
      png: "https://flagcdn.com/w320/gr.png",
      svg: "https://flagcdn.com/gr.svg",
      alt: "The flag of Greece is composed of nine equal horizontal bands of blue alternating with white. A blue square bearing a white cross is superimposed in the canton.",
    },
  },
  {
    name: {
      en: "Greenland",
      ar: "جرينلاند",
    },
    iso2: "GL",
    iso3: "GRL",
    currency: {
      DKK: {
        name: "krone",
        symbol: "kr.",
      },
    },
    country_code: "+299",
    region: "Americas",
    subregion: "North America",
    flags: {
      png: "https://flagcdn.com/w320/gl.png",
      svg: "https://flagcdn.com/gl.svg",
    },
  },
  {
    name: {
      en: "Grenada",
      ar: "غرينادا",
    },
    iso2: "GD",
    iso3: "GRD",
    currency: {
      XCD: {
        name: "Eastern Caribbean dollar",
        symbol: "$",
      },
    },
    country_code: "+1473",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/gd.png",
      svg: "https://flagcdn.com/gd.svg",
      alt: "The flag of Grenada features a large central rectangular area surrounded by a red border, with three five-pointed yellow stars centered on the top and bottom borders. The central rectangle is divided diagonally into four alternating triangular areas of yellow at the top and bottom and green on the hoist and fly sides, and a five-pointed yellow star on a red circle is superimposed at its center. A symbolic nutmeg pod is situated on the green hoist-side triangle.",
    },
  },
  {
    name: {
      en: "Guadeloupe",
      ar: "غوادلوب",
    },
    iso2: "GP",
    iso3: "GLP",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+590",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/gp.png",
      svg: "https://flagcdn.com/gp.svg",
    },
  },
  {
    name: {
      en: "Guam",
      ar: "غوام",
    },
    iso2: "GU",
    iso3: "GUM",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+1671",
    region: "Oceania",
    subregion: "Micronesia",
    flags: {
      png: "https://flagcdn.com/w320/gu.png",
      svg: "https://flagcdn.com/gu.svg",
    },
  },
  {
    name: {
      en: "Guatemala",
      ar: "غواتيمالا",
    },
    iso2: "GT",
    iso3: "GTM",
    currency: {
      GTQ: {
        name: "Guatemalan quetzal",
        symbol: "Q",
      },
    },
    country_code: "+502",
    region: "Americas",
    subregion: "Central America",
    flags: {
      png: "https://flagcdn.com/w320/gt.png",
      svg: "https://flagcdn.com/gt.svg",
      alt: "The flag of Guatemala is composed of three equal vertical bands of light blue, white and light blue, with the national coat of arms centered in the white band.",
    },
  },
  {
    name: {
      en: "Guernsey",
      ar: "غيرنزي",
    },
    iso2: "GG",
    iso3: "GGY",
    currency: {
      GBP: {
        name: "British pound",
        symbol: "£",
      },
      GGP: {
        name: "Guernsey pound",
        symbol: "£",
      },
    },
    country_code: "+44",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/gg.png",
      svg: "https://flagcdn.com/gg.svg",
    },
  },
  {
    name: {
      en: "Guinea",
      ar: "غينيا",
    },
    iso2: "GN",
    iso3: "GIN",
    currency: {
      GNF: {
        name: "Guinean franc",
        symbol: "Fr",
      },
    },
    country_code: "+224",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/gn.png",
      svg: "https://flagcdn.com/gn.svg",
      alt: "The flag of Guinea is composed of three equal vertical bands of red, yellow and green.",
    },
  },
  {
    name: {
      en: "Guinea-Bissau",
      ar: "غينيا بيساو",
    },
    iso2: "GW",
    iso3: "GNB",
    currency: {
      XOF: {
        name: "West African CFA franc",
        symbol: "Fr",
      },
    },
    country_code: "+245",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/gw.png",
      svg: "https://flagcdn.com/gw.svg",
      alt: "The flag of Guinea-Bissau features a red vertical band on its hoist side that takes up about two-fifth the width of the field, and two equal horizontal bands of yellow and green adjoining the vertical band. A five-pointed black star is centered in the vertical band.",
    },
  },
  {
    name: {
      en: "Guyana",
      ar: "غيانا",
    },
    iso2: "GY",
    iso3: "GUY",
    currency: {
      GYD: {
        name: "Guyanese dollar",
        symbol: "$",
      },
    },
    country_code: "+592",
    region: "Americas",
    subregion: "South America",
    flags: {
      png: "https://flagcdn.com/w320/gy.png",
      svg: "https://flagcdn.com/gy.svg",
      alt: "The flag of Guyana has a green field with two isosceles triangles which share a common base on the hoist end. The smaller black-edged red triangle spanning half the width of the field is superimposed on the larger white-edged yellow triangle which spans the full width of the field.",
    },
  },
  {
    name: {
      en: "Haiti",
      ar: "هايتي",
    },
    iso2: "HT",
    iso3: "HTI",
    currency: {
      HTG: {
        name: "Haitian gourde",
        symbol: "G",
      },
    },
    country_code: "+509",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/ht.png",
      svg: "https://flagcdn.com/ht.svg",
      alt: "The flag of Haiti is composed of two equal horizontal bands of blue and red. A white square bearing the national coat of arms is superimposed at the center of the field.",
    },
  },
  {
    name: {
      en: "Heard Island and McDonald Islands",
      ar: "جزيرة هيرد وجزر ماكدونالد",
    },
    iso2: "HM",
    iso3: "HMD",
    country_code: null,
    region: "Antarctic",
    flags: {
      png: "https://flagcdn.com/w320/hm.png",
      svg: "https://flagcdn.com/hm.svg",
    },
  },
  {
    name: {
      en: "Honduras",
      ar: "هندوراس",
    },
    iso2: "HN",
    iso3: "HND",
    currency: {
      HNL: {
        name: "Honduran lempira",
        symbol: "L",
      },
    },
    country_code: "+504",
    region: "Americas",
    subregion: "Central America",
    flags: {
      png: "https://flagcdn.com/w320/hn.png",
      svg: "https://flagcdn.com/hn.svg",
      alt: "The flag of Honduras is composed of three equal horizontal bands of turquoise, white and turquoise, with five small five-pointed turquoise stars arranged in a quincuncial pattern at the center of the white band.",
    },
  },
  {
    name: {
      en: "Hong Kong",
      ar: "هونغ كونغ",
    },
    iso2: "HK",
    iso3: "HKG",
    currency: {
      HKD: {
        name: "Hong Kong dollar",
        symbol: "$",
      },
    },
    country_code: "+852",
    region: "Asia",
    subregion: "Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/hk.png",
      svg: "https://flagcdn.com/hk.svg",
    },
  },
  {
    name: {
      en: "Hungary",
      ar: "المجر",
    },
    iso2: "HU",
    iso3: "HUN",
    currency: {
      HUF: {
        name: "Hungarian forint",
        symbol: "Ft",
      },
    },
    country_code: "+36",
    region: "Europe",
    subregion: "Central Europe",
    flags: {
      png: "https://flagcdn.com/w320/hu.png",
      svg: "https://flagcdn.com/hu.svg",
      alt: "The flag of Hungary is composed of three equal horizontal bands of red, white and green.",
    },
  },
  {
    name: {
      en: "Iceland",
      ar: "آيسلندا",
    },
    iso2: "IS",
    iso3: "ISL",
    currency: {
      ISK: {
        name: "Icelandic króna",
        symbol: "kr",
      },
    },
    country_code: "+354",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/is.png",
      svg: "https://flagcdn.com/is.svg",
      alt: "The flag of Iceland has a blue field with a large white-edged red cross that extends to the edges of the field. The vertical part of this cross is offset towards the hoist side.",
    },
  },
  {
    name: {
      en: "India",
      ar: "الهند",
    },
    iso2: "IN",
    iso3: "IND",
    currency: {
      INR: {
        name: "Indian rupee",
        symbol: "₹",
      },
    },
    country_code: "+91",
    region: "Asia",
    subregion: "Southern Asia",
    flags: {
      png: "https://flagcdn.com/w320/in.png",
      svg: "https://flagcdn.com/in.svg",
      alt: "The flag of India is composed of three equal horizontal bands of saffron, white and green. A navy blue wheel with twenty-four spokes — the Ashoka Chakra — is centered in the white band.",
    },
  },
  {
    name: {
      en: "Indonesia",
      ar: "إندونيسيا",
    },
    iso2: "ID",
    iso3: "IDN",
    currency: {
      IDR: {
        name: "Indonesian rupiah",
        symbol: "Rp",
      },
    },
    country_code: "+62",
    region: "Asia",
    subregion: "South-Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/id.png",
      svg: "https://flagcdn.com/id.svg",
      alt: "The flag of Indonesia is composed of two equal horizontal bands of red and white.",
    },
  },
  {
    name: {
      en: "Iran",
      ar: "إيران",
    },
    iso2: "IR",
    iso3: "IRN",
    currency: {
      IRR: {
        name: "Iranian rial",
        symbol: "﷼",
      },
    },
    country_code: "+98",
    region: "Asia",
    subregion: "Southern Asia",
    flags: {
      png: "https://flagcdn.com/w320/ir.png",
      svg: "https://flagcdn.com/ir.svg",
      alt: "The flag of Iran is composed of three equal horizontal bands of green, white and red. A red emblem of Iran is centered in the white band and Arabic inscriptions in white span the bottom edge of the green band and the top edge of the red band.",
    },
  },
  {
    name: {
      en: "Iraq",
      ar: "العراق",
    },
    iso2: "IQ",
    iso3: "IRQ",
    currency: {
      IQD: {
        name: "Iraqi dinar",
        symbol: "ع.د",
      },
    },
    country_code: "+964",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/iq.png",
      svg: "https://flagcdn.com/iq.svg",
      alt: "The flag of Iraq is composed of three equal horizontal bands of red, white and black. In the central white band are Arabic inscriptions in green.",
    },
  },
  {
    name: {
      en: "Ireland",
      ar: "أيرلندا",
    },
    iso2: "IE",
    iso3: "IRL",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+353",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/ie.png",
      svg: "https://flagcdn.com/ie.svg",
      alt: "The flag of Ireland is composed of three equal vertical bands of green, white and orange.",
    },
  },
  {
    name: {
      en: "Isle of Man",
      ar: "جزيرة مان",
    },
    iso2: "IM",
    iso3: "IMN",
    currency: {
      GBP: {
        name: "British pound",
        symbol: "£",
      },
      IMP: {
        name: "Manx pound",
        symbol: "£",
      },
    },
    country_code: "+44",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/im.png",
      svg: "https://flagcdn.com/im.svg",
    },
  },
  {
    name: {
      en: "Israel",
      ar: "إسرائيل",
    },
    iso2: "IL",
    iso3: "ISR",
    currency: {
      ILS: {
        name: "Israeli new shekel",
        symbol: "₪",
      },
    },
    country_code: "+972",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/il.png",
      svg: "https://flagcdn.com/il.svg",
      alt: "The flag of Israel has a white field with a blue hexagram — the Magen David — centered between two equal horizontal blue bands situated near the top and bottom edges of the field.",
    },
  },
  {
    name: {
      en: "Italy",
      ar: "إيطاليا",
    },
    iso2: "IT",
    iso3: "ITA",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+39",
    region: "Europe",
    subregion: "Southern Europe",
    flags: {
      png: "https://flagcdn.com/w320/it.png",
      svg: "https://flagcdn.com/it.svg",
      alt: "The flag of Italy is composed of three equal vertical bands of green, white and red.",
    },
  },
  {
    name: {
      en: "Ivory Coast",
      ar: "ساحل العاج",
    },
    iso2: "CI",
    iso3: "CIV",
    currency: {
      XOF: {
        name: "West African CFA franc",
        symbol: "Fr",
      },
    },
    country_code: "+225",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/ci.png",
      svg: "https://flagcdn.com/ci.svg",
      alt: "The flag of Ivory Coast is composed of three equal vertical bands of orange, white and green.",
    },
  },
  {
    name: {
      en: "Jamaica",
      ar: "جامايكا",
    },
    iso2: "JM",
    iso3: "JAM",
    currency: {
      JMD: {
        name: "Jamaican dollar",
        symbol: "$",
      },
    },
    country_code: "+1876",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/jm.png",
      svg: "https://flagcdn.com/jm.svg",
      alt: "The flag of Jamaica is divided by a gold diagonal cross into four alternating triangular areas of green at the top and bottom, and black on the hoist and fly sides",
    },
  },
  {
    name: {
      en: "Japan",
      ar: "اليابان",
    },
    iso2: "JP",
    iso3: "JPN",
    currency: {
      JPY: {
        name: "Japanese yen",
        symbol: "¥",
      },
    },
    country_code: "+81",
    region: "Asia",
    subregion: "Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/jp.png",
      svg: "https://flagcdn.com/jp.svg",
      alt: "The flag of Japan features a crimson-red circle at the center of a white field.",
    },
  },
  {
    name: {
      en: "Jersey",
      ar: "جيرزي",
    },
    iso2: "JE",
    iso3: "JEY",
    currency: {
      GBP: {
        name: "British pound",
        symbol: "£",
      },
      JEP: {
        name: "Jersey pound",
        symbol: "£",
      },
    },
    country_code: "+44",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/je.png",
      svg: "https://flagcdn.com/je.svg",
    },
  },
  {
    name: {
      en: "Jordan",
      ar: "الأردن",
    },
    iso2: "JO",
    iso3: "JOR",
    currency: {
      JOD: {
        name: "Jordanian dinar",
        symbol: "د.ا",
      },
    },
    country_code: "+962",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/jo.png",
      svg: "https://flagcdn.com/jo.svg",
      alt: "The flag of Jordan is composed of three equal horizontal bands of black, white and green, with a red isosceles triangle superimposed on the hoist side of the field. This triangle has its base on the hoist end, spans about half the width of the field and bears a small seven-pointed white star at its center.",
    },
  },
  {
    name: {
      en: "Kazakhstan",
      ar: "كازاخستان",
    },
    iso2: "KZ",
    iso3: "KAZ",
    currency: {
      KZT: {
        name: "Kazakhstani tenge",
        symbol: "₸",
      },
    },
    country_code: "+76",
    region: "Asia",
    subregion: "Central Asia",
    flags: {
      png: "https://flagcdn.com/w320/kz.png",
      svg: "https://flagcdn.com/kz.svg",
      alt: "The flag of Kazakhstan has a turquoise field, at the center of which is a gold sun with thirty-two rays above a soaring golden steppe eagle. A thin vertical band displays a national ornamental pattern — koshkar-muiz — in gold near the hoist end.",
    },
  },
  {
    name: {
      en: "Kenya",
      ar: "كينيا",
    },
    iso2: "KE",
    iso3: "KEN",
    currency: {
      KES: {
        name: "Kenyan shilling",
        symbol: "Sh",
      },
    },
    country_code: "+254",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/ke.png",
      svg: "https://flagcdn.com/ke.svg",
      alt: "The flag of Kenya is composed of three equal horizontal bands of black, red with white top and bottom edges, and green. An emblem comprising a red, black and white Maasai shield covering two crossed white spears is superimposed at the center of the field.",
    },
  },
  {
    name: {
      en: "Kiribati",
      ar: "كيريباتي",
    },
    iso2: "KI",
    iso3: "KIR",
    currency: {
      AUD: {
        name: "Australian dollar",
        symbol: "$",
      },
      KID: {
        name: "Kiribati dollar",
        symbol: "$",
      },
    },
    country_code: "+686",
    region: "Oceania",
    subregion: "Micronesia",
    flags: {
      png: "https://flagcdn.com/w320/ki.png",
      svg: "https://flagcdn.com/ki.svg",
      alt: "The flag of Kiribati is divided into two halves. While the upper half has a red field, at the center of which is a yellow frigate bird flying over the top half of a rising yellow sun with seventeen visible rays, the lower half is composed of six horizontal wavy bands of white alternating with blue to depict the ocean.",
    },
  },
  {
    name: {
      en: "Kosovo",
      ar: "كوسوفو",
    },
    iso2: "XK",
    iso3: "UNK",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+383",
    region: "Europe",
    subregion: "Southeast Europe",
    flags: {
      png: "https://flagcdn.com/w320/xk.png",
      svg: "https://flagcdn.com/xk.svg",
    },
  },
  {
    name: {
      en: "Kuwait",
      ar: "الكويت",
    },
    iso2: "KW",
    iso3: "KWT",
    currency: {
      KWD: {
        name: "Kuwaiti dinar",
        symbol: "د.ك",
      },
    },
    country_code: "+965",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/kw.png",
      svg: "https://flagcdn.com/kw.svg",
      alt: "The flag of Kuwait is composed of three equal horizontal bands of green, white and red, with a black trapezium superimposed on the hoist side of the field. This trapezium has its base on the hoist end and spans about one-fourth the width of the field.",
    },
  },
  {
    name: {
      en: "Kyrgyzstan",
      ar: "قيرغيزستان",
    },
    iso2: "KG",
    iso3: "KGZ",
    currency: {
      KGS: {
        name: "Kyrgyzstani som",
        symbol: "с",
      },
    },
    country_code: "+996",
    region: "Asia",
    subregion: "Central Asia",
    flags: {
      png: "https://flagcdn.com/w320/kg.png",
      svg: "https://flagcdn.com/kg.svg",
      alt: "The flag of Kyrgyzstan features a yellow sun with forty rays at the center of a red field. At the center of the sun is a stylized depiction of a tunduk.",
    },
  },
  {
    name: {
      en: "Laos",
      ar: "لاوس",
    },
    iso2: "LA",
    iso3: "LAO",
    currency: {
      LAK: {
        name: "Lao kip",
        symbol: "₭",
      },
    },
    country_code: "+856",
    region: "Asia",
    subregion: "South-Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/la.png",
      svg: "https://flagcdn.com/la.svg",
      alt: "The flag of Laos is composed of three horizontal bands of red, blue and red. The blue band is twice the height of the red bands and bears a white circle at its center.",
    },
  },
  {
    name: {
      en: "Latvia",
      ar: "لاتفيا",
    },
    iso2: "LV",
    iso3: "LVA",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+371",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/lv.png",
      svg: "https://flagcdn.com/lv.svg",
      alt: "The flag of Latvia has a carmine-red field with a thin white horizontal band across the middle of the field.",
    },
  },
  {
    name: {
      en: "Lebanon",
      ar: "لبنان",
    },
    iso2: "LB",
    iso3: "LBN",
    currency: {
      LBP: {
        name: "Lebanese pound",
        symbol: "ل.ل",
      },
    },
    country_code: "+961",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/lb.png",
      svg: "https://flagcdn.com/lb.svg",
      alt: "The flag of Lebanon is composed of three horizontal bands of red, white and red. The white band is twice the height of the red bands and bears a green Lebanese Cedar tree at its center.",
    },
  },
  {
    name: {
      en: "Lesotho",
      ar: "ليسوتو",
    },
    iso2: "LS",
    iso3: "LSO",
    currency: {
      LSL: {
        name: "Lesotho loti",
        symbol: "L",
      },
      ZAR: {
        name: "South African rand",
        symbol: "R",
      },
    },
    country_code: "+266",
    region: "Africa",
    subregion: "Southern Africa",
    flags: {
      png: "https://flagcdn.com/w320/ls.png",
      svg: "https://flagcdn.com/ls.svg",
      alt: "The flag of Lesotho is composed of three horizontal bands of blue, white and green in the ratio of 3:4:3. A black mokorotlo — a Basotho hat — is centered in the white band.",
    },
  },
  {
    name: {
      en: "Liberia",
      ar: "ليبيريا",
    },
    iso2: "LR",
    iso3: "LBR",
    currency: {
      LRD: {
        name: "Liberian dollar",
        symbol: "$",
      },
    },
    country_code: "+231",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/lr.png",
      svg: "https://flagcdn.com/lr.svg",
      alt: "The flag of Liberia is composed of eleven equal horizontal bands of red alternating with white. A blue square bearing a five-pointed white star is superimposed in the canton.",
    },
  },
  {
    name: {
      en: "Libya",
      ar: "‏ليبيا",
    },
    iso2: "LY",
    iso3: "LBY",
    currency: {
      LYD: {
        name: "Libyan dinar",
        symbol: "ل.د",
      },
    },
    country_code: "+218",
    region: "Africa",
    subregion: "Northern Africa",
    flags: {
      png: "https://flagcdn.com/w320/ly.png",
      svg: "https://flagcdn.com/ly.svg",
      alt: "The flag of Libya is composed of three horizontal bands of red, black and green, with the black band twice the height of the other two bands. At the center of the black band is a fly-side facing white crescent and a five-pointed white star placed just outside the crescent opening.",
    },
  },
  {
    name: {
      en: "Liechtenstein",
      ar: "ليختنشتاين",
    },
    iso2: "LI",
    iso3: "LIE",
    currency: {
      CHF: {
        name: "Swiss franc",
        symbol: "Fr",
      },
    },
    country_code: "+423",
    region: "Europe",
    subregion: "Western Europe",
    flags: {
      png: "https://flagcdn.com/w320/li.png",
      svg: "https://flagcdn.com/li.svg",
      alt: "The flag of Liechtenstein is composed of two equal horizontal bands of blue and red, with a golden-yellow crown on the hoist side of the blue band.",
    },
  },
  {
    name: {
      en: "Lithuania",
      ar: "ليتوانيا",
    },
    iso2: "LT",
    iso3: "LTU",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+370",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/lt.png",
      svg: "https://flagcdn.com/lt.svg",
      alt: "The flag of Lithuania is composed of three equal horizontal bands of yellow, green and red.",
    },
  },
  {
    name: {
      en: "Luxembourg",
      ar: "لوكسمبورغ",
    },
    iso2: "LU",
    iso3: "LUX",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+352",
    region: "Europe",
    subregion: "Western Europe",
    flags: {
      png: "https://flagcdn.com/w320/lu.png",
      svg: "https://flagcdn.com/lu.svg",
      alt: "The flag of Luxembourg is composed of three equal horizontal bands of red, white and light blue.",
    },
  },
  {
    name: {
      en: "Macau",
      ar: "ماكاو",
    },
    iso2: "MO",
    iso3: "MAC",
    currency: {
      MOP: {
        name: "Macanese pataca",
        symbol: "P",
      },
    },
    country_code: "+853",
    region: "Asia",
    subregion: "Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/mo.png",
      svg: "https://flagcdn.com/mo.svg",
    },
  },
  {
    name: {
      en: "Madagascar",
      ar: "مدغشقر",
    },
    iso2: "MG",
    iso3: "MDG",
    currency: {
      MGA: {
        name: "Malagasy ariary",
        symbol: "Ar",
      },
    },
    country_code: "+261",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/mg.png",
      svg: "https://flagcdn.com/mg.svg",
      alt: "The flag of Madagascar features a white vertical band on the hoist side that takes up about one-third the width of the field, and two equal horizontal bands of red and green adjoining the vertical band.",
    },
  },
  {
    name: {
      en: "Malawi",
      ar: "مالاوي",
    },
    iso2: "MW",
    iso3: "MWI",
    currency: {
      MWK: {
        name: "Malawian kwacha",
        symbol: "MK",
      },
    },
    country_code: "+265",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/mw.png",
      svg: "https://flagcdn.com/mw.svg",
      alt: "The flag of Malawi is composed of three equal horizontal bands of black, red and green. The top half of a red sun with thirty-one visible rays is centered in the black band.",
    },
  },
  {
    name: {
      en: "Malaysia",
      ar: "ماليزيا",
    },
    iso2: "MY",
    iso3: "MYS",
    currency: {
      MYR: {
        name: "Malaysian ringgit",
        symbol: "RM",
      },
    },
    country_code: "+60",
    region: "Asia",
    subregion: "South-Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/my.png",
      svg: "https://flagcdn.com/my.svg",
      alt: "The flag of Malaysia is composed of fourteen equal horizontal bands of red alternating with white. A blue rectangle, bearing a fly-side facing yellow crescent and a fourteen-pointed yellow star placed just outside the crescent opening, is superimposed in the canton.",
    },
  },
  {
    name: {
      en: "Maldives",
      ar: "المالديف",
    },
    iso2: "MV",
    iso3: "MDV",
    currency: {
      MVR: {
        name: "Maldivian rufiyaa",
        symbol: ".ރ",
      },
    },
    country_code: "+960",
    region: "Asia",
    subregion: "Southern Asia",
    flags: {
      png: "https://flagcdn.com/w320/mv.png",
      svg: "https://flagcdn.com/mv.svg",
      alt: "The flag of Maldives has a red field, at the center of which is a large green rectangle bearing a fly-side facing white crescent.",
    },
  },
  {
    name: {
      en: "Mali",
      ar: "مالي",
    },
    iso2: "ML",
    iso3: "MLI",
    currency: {
      XOF: {
        name: "West African CFA franc",
        symbol: "Fr",
      },
    },
    country_code: "+223",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/ml.png",
      svg: "https://flagcdn.com/ml.svg",
      alt: "The flag of Mali is composed of three equal vertical bands of green, yellow and red.",
    },
  },
  {
    name: {
      en: "Malta",
      ar: "مالطا",
    },
    iso2: "MT",
    iso3: "MLT",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+356",
    region: "Europe",
    subregion: "Southern Europe",
    flags: {
      png: "https://flagcdn.com/w320/mt.png",
      svg: "https://flagcdn.com/mt.svg",
      alt: "The flag of Malta is composed of two equal vertical bands of white and red. A representation of the George cross edged in red is situated on the upper hoist-side corner of the white band.",
    },
  },
  {
    name: {
      en: "Marshall Islands",
      ar: "جزر مارشال",
    },
    iso2: "MH",
    iso3: "MHL",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+692",
    region: "Oceania",
    subregion: "Micronesia",
    flags: {
      png: "https://flagcdn.com/w320/mh.png",
      svg: "https://flagcdn.com/mh.svg",
      alt: "The flag of Marshall Islands has a blue field with two broadening adjacent diagonal bands of orange and white that extend from the lower hoist-side corner to the upper fly-side corner of the field. A large white star with twenty-four rays — four large rays at the cardinal points and twenty smaller rays — is situated in the upper hoist-side corner above the diagonal bands.",
    },
  },
  {
    name: {
      en: "Martinique",
      ar: "مارتينيك",
    },
    iso2: "MQ",
    iso3: "MTQ",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+596",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/mq.png",
      svg: "https://flagcdn.com/mq.svg",
    },
  },
  {
    name: {
      en: "Mauritania",
      ar: "موريتانيا",
    },
    iso2: "MR",
    iso3: "MRT",
    currency: {
      MRU: {
        name: "Mauritanian ouguiya",
        symbol: "UM",
      },
    },
    country_code: "+222",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/mr.png",
      svg: "https://flagcdn.com/mr.svg",
      alt: "The flag of Mauritania has a green field with a thin red horizontal band at the top and bottom of the field. At the center of the field is a five-pointed yellow star above an upward facing yellow crescent.",
    },
  },
  {
    name: {
      en: "Mauritius",
      ar: "موريشيوس",
    },
    iso2: "MU",
    iso3: "MUS",
    currency: {
      MUR: {
        name: "Mauritian rupee",
        symbol: "₨",
      },
    },
    country_code: "+230",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/mu.png",
      svg: "https://flagcdn.com/mu.svg",
      alt: "The flag of Mauritius is composed of four equal horizontal bands of red, blue, yellow and green.",
    },
  },
  {
    name: {
      en: "Mayotte",
      ar: "مايوت",
    },
    iso2: "YT",
    iso3: "MYT",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+262",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/yt.png",
      svg: "https://flagcdn.com/yt.svg",
    },
  },
  {
    name: {
      en: "Mexico",
      ar: "المسكيك",
    },
    iso2: "MX",
    iso3: "MEX",
    currency: {
      MXN: {
        name: "Mexican peso",
        symbol: "$",
      },
    },
    country_code: "+52",
    region: "Americas",
    subregion: "North America",
    flags: {
      png: "https://flagcdn.com/w320/mx.png",
      svg: "https://flagcdn.com/mx.svg",
      alt: "The flag of Mexico is composed of three equal vertical bands of green, white and red, with the national coat of arms centered in the white band.",
    },
  },
  {
    name: {
      en: "Micronesia",
      ar: "ميكرونيسيا",
    },
    iso2: "FM",
    iso3: "FSM",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+691",
    region: "Oceania",
    subregion: "Micronesia",
    flags: {
      png: "https://flagcdn.com/w320/fm.png",
      svg: "https://flagcdn.com/fm.svg",
      alt: "The flag of Micronesia has a light blue field, at the center of which are four five-pointed white stars arranged in the shape of a diamond.",
    },
  },
  {
    name: {
      en: "Moldova",
      ar: "مولدوڤا",
    },
    iso2: "MD",
    iso3: "MDA",
    currency: {
      MDL: {
        name: "Moldovan leu",
        symbol: "L",
      },
    },
    country_code: "+373",
    region: "Europe",
    subregion: "Eastern Europe",
    flags: {
      png: "https://flagcdn.com/w320/md.png",
      svg: "https://flagcdn.com/md.svg",
      alt: "The flag of Moldova is composed of three equal vertical bands of blue, yellow and red, with the national coat of arms centered in the yellow band.",
    },
  },
  {
    name: {
      en: "Monaco",
      ar: "موناكو",
    },
    iso2: "MC",
    iso3: "MCO",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+377",
    region: "Europe",
    subregion: "Western Europe",
    flags: {
      png: "https://flagcdn.com/w320/mc.png",
      svg: "https://flagcdn.com/mc.svg",
      alt: "The flag of Monaco is composed of two equal horizontal bands of red and white.",
    },
  },
  {
    name: {
      en: "Mongolia",
      ar: "منغوليا",
    },
    iso2: "MN",
    iso3: "MNG",
    currency: {
      MNT: {
        name: "Mongolian tögrög",
        symbol: "₮",
      },
    },
    country_code: "+976",
    region: "Asia",
    subregion: "Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/mn.png",
      svg: "https://flagcdn.com/mn.svg",
      alt: "The flag of Mongolia is composed of three equal vertical bands of red, blue and red, with the national emblem — the Soyombo — in gold centered in the hoist-side red band.",
    },
  },
  {
    name: {
      en: "Montenegro",
      ar: "الجبل الاسود",
    },
    iso2: "ME",
    iso3: "MNE",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+382",
    region: "Europe",
    subregion: "Southeast Europe",
    flags: {
      png: "https://flagcdn.com/w320/me.png",
      svg: "https://flagcdn.com/me.svg",
      alt: "The flag of Montenegro features a large red central rectangular area surrounded by a golden-yellow border. The coat of arms of Montenegro is centered in the red rectangle.",
    },
  },
  {
    name: {
      en: "Montserrat",
      ar: "مونتسرات",
    },
    iso2: "MS",
    iso3: "MSR",
    currency: {
      XCD: {
        name: "Eastern Caribbean dollar",
        symbol: "$",
      },
    },
    country_code: "+1664",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/ms.png",
      svg: "https://flagcdn.com/ms.svg",
    },
  },
  {
    name: {
      en: "Morocco",
      ar: "المغرب",
    },
    iso2: "MA",
    iso3: "MAR",
    currency: {
      MAD: {
        name: "Moroccan dirham",
        symbol: "د.م.",
      },
    },
    country_code: "+212",
    region: "Africa",
    subregion: "Northern Africa",
    flags: {
      png: "https://flagcdn.com/w320/ma.png",
      svg: "https://flagcdn.com/ma.svg",
      alt: "The flag of Morocco features a green pentagram — a five-pointed linear star — centered on a red field.",
    },
  },
  {
    name: {
      en: "Mozambique",
      ar: "موزمبيق",
    },
    iso2: "MZ",
    iso3: "MOZ",
    currency: {
      MZN: {
        name: "Mozambican metical",
        symbol: "MT",
      },
    },
    country_code: "+258",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/mz.png",
      svg: "https://flagcdn.com/mz.svg",
      alt: "The flag of Mozambique is composed of three equal horizontal bands of teal, black with white top and bottom edges, and yellow. A red isosceles triangle spanning about two-fifth the width of the field is superimposed on the hoist side with its base on the hoist end. This triangle bears a crossed rifle and hoe in black superimposed on an open white book which is superimposed on a five-pointed yellow star.",
    },
  },
  {
    name: {
      en: "Myanmar",
      ar: "ميانمار",
    },
    iso2: "MM",
    iso3: "MMR",
    currency: {
      MMK: {
        name: "Burmese kyat",
        symbol: "Ks",
      },
    },
    country_code: "+95",
    region: "Asia",
    subregion: "South-Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/mm.png",
      svg: "https://flagcdn.com/mm.svg",
      alt: "The flag of Myanmar is composed of three equal horizontal bands of yellow, green and red, with a large five-pointed white star superimposed at the center of the field.",
    },
  },
  {
    name: {
      en: "Namibia",
      ar: "ناميبيا",
    },
    iso2: "NA",
    iso3: "NAM",
    currency: {
      NAD: {
        name: "Namibian dollar",
        symbol: "$",
      },
      ZAR: {
        name: "South African rand",
        symbol: "R",
      },
    },
    country_code: "+264",
    region: "Africa",
    subregion: "Southern Africa",
    flags: {
      png: "https://flagcdn.com/w320/na.png",
      svg: "https://flagcdn.com/na.svg",
      alt: "The flag of Namibia features a white-edged red diagonal band that extends from the lower hoist-side corner to the upper fly-side corner of the field. Above and beneath this band are a blue and green triangle respectively. A gold sun with twelve triangular rays is situated on the hoist side of the upper triangle.",
    },
  },
  {
    name: {
      en: "Nauru",
      ar: "ناورو",
    },
    iso2: "NR",
    iso3: "NRU",
    currency: {
      AUD: {
        name: "Australian dollar",
        symbol: "$",
      },
    },
    country_code: "+674",
    region: "Oceania",
    subregion: "Micronesia",
    flags: {
      png: "https://flagcdn.com/w320/nr.png",
      svg: "https://flagcdn.com/nr.svg",
      alt: "The flag of Nauru has a dark blue field with a thin yellow horizontal band across the center and a large white twelve-pointed star beneath the horizontal band on the hoist side of the field.",
    },
  },
  {
    name: {
      en: "Nepal",
      ar: "نيبال",
    },
    iso2: "NP",
    iso3: "NPL",
    currency: {
      NPR: {
        name: "Nepalese rupee",
        symbol: "₨",
      },
    },
    country_code: "+977",
    region: "Asia",
    subregion: "Southern Asia",
    flags: {
      png: "https://flagcdn.com/w320/np.png",
      svg: "https://flagcdn.com/np.svg",
      alt: "The flag of Nepal is the world's only non-quadrilateral flag of a sovereign country. It takes the shape of two adjoining right-angled triangles and has a crimson red field with deep blue edges. Within the smaller upper triangle is an emblem of the upper half of a white sun resting on an upward facing white crescent. The lower triangle bears a white sun with twelve rays.",
    },
  },
  {
    name: {
      en: "Netherlands",
      ar: "هولندا",
    },
    iso2: "NL",
    iso3: "NLD",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+31",
    region: "Europe",
    subregion: "Western Europe",
    flags: {
      png: "https://flagcdn.com/w320/nl.png",
      svg: "https://flagcdn.com/nl.svg",
      alt: "The flag of the Netherlands is composed of three equal horizontal bands of red, white and blue.",
    },
  },
  {
    name: {
      en: "New Caledonia",
      ar: "كاليدونيا الجديدة",
    },
    iso2: "NC",
    iso3: "NCL",
    currency: {
      XPF: {
        name: "CFP franc",
        symbol: "₣",
      },
    },
    country_code: "+687",
    region: "Oceania",
    subregion: "Melanesia",
    flags: {
      png: "https://flagcdn.com/w320/nc.png",
      svg: "https://flagcdn.com/nc.svg",
    },
  },
  {
    name: {
      en: "New Zealand",
      ar: "نيوزيلندا",
    },
    iso2: "NZ",
    iso3: "NZL",
    currency: {
      NZD: {
        name: "New Zealand dollar",
        symbol: "$",
      },
    },
    country_code: "+64",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    flags: {
      png: "https://flagcdn.com/w320/nz.png",
      svg: "https://flagcdn.com/nz.svg",
      alt: "The flag of New Zealand has a dark blue field with the flag of the United Kingdom — the Union Jack — in the canton and a representation of the Southern Cross constellation, made up of four five-pointed white-edged red stars, on the fly side of the field.",
    },
  },
  {
    name: {
      en: "Nicaragua",
      ar: "نيكاراغوا",
    },
    iso2: "NI",
    iso3: "NIC",
    currency: {
      NIO: {
        name: "Nicaraguan córdoba",
        symbol: "C$",
      },
    },
    country_code: "+505",
    region: "Americas",
    subregion: "Central America",
    flags: {
      png: "https://flagcdn.com/w320/ni.png",
      svg: "https://flagcdn.com/ni.svg",
      alt: "The flag of Nicaragua is composed of three equal horizontal bands of blue, white and blue, with the national coat of arms centered in the white band.",
    },
  },
  {
    name: {
      en: "Niger",
      ar: "النيجر",
    },
    iso2: "NE",
    iso3: "NER",
    currency: {
      XOF: {
        name: "West African CFA franc",
        symbol: "Fr",
      },
    },
    country_code: "+227",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/ne.png",
      svg: "https://flagcdn.com/ne.svg",
      alt: "The flag of Niger features three equal horizontal bands of orange, white and green, with an orange circle centered in the white band.",
    },
  },
  {
    name: {
      en: "Nigeria",
      ar: "نيجيريا",
    },
    iso2: "NG",
    iso3: "NGA",
    currency: {
      NGN: {
        name: "Nigerian naira",
        symbol: "₦",
      },
    },
    country_code: "+234",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/ng.png",
      svg: "https://flagcdn.com/ng.svg",
      alt: "The flag of Nigeria is composed of three equal vertical bands of green, white and green.",
    },
  },
  {
    name: {
      en: "Niue",
      ar: "نييوي",
    },
    iso2: "NU",
    iso3: "NIU",
    currency: {
      NZD: {
        name: "New Zealand dollar",
        symbol: "$",
      },
    },
    country_code: "+683",
    region: "Oceania",
    subregion: "Polynesia",
    flags: {
      png: "https://flagcdn.com/w320/nu.png",
      svg: "https://flagcdn.com/nu.svg",
    },
  },
  {
    name: {
      en: "Norfolk Island",
      ar: "جزيرة نورفولك",
    },
    iso2: "NF",
    iso3: "NFK",
    currency: {
      AUD: {
        name: "Australian dollar",
        symbol: "$",
      },
    },
    country_code: "+672",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    flags: {
      png: "https://flagcdn.com/w320/nf.png",
      svg: "https://flagcdn.com/nf.svg",
    },
  },
  {
    name: {
      en: "North Korea",
      ar: "كوريا الشمالية",
    },
    iso2: "KP",
    iso3: "PRK",
    currency: {
      KPW: {
        name: "North Korean won",
        symbol: "₩",
      },
    },
    country_code: "+850",
    region: "Asia",
    subregion: "Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/kp.png",
      svg: "https://flagcdn.com/kp.svg",
      alt: "The flag of North Korea is composed of three horizontal bands — a large central white-edged red band, and a blue band above and beneath the red band. On the hoist side of the red band is a red five-pointed star within a white circle.",
    },
  },
  {
    name: {
      en: "North Macedonia",
      ar: "شمال مقدونيا",
    },
    iso2: "MK",
    iso3: "MKD",
    currency: {
      MKD: {
        name: "denar",
        symbol: "den",
      },
    },
    country_code: "+389",
    region: "Europe",
    subregion: "Southeast Europe",
    flags: {
      png: "https://flagcdn.com/w320/mk.png",
      svg: "https://flagcdn.com/mk.svg",
      alt: "The flag of North Macedonia has a red field, at the center of which is a golden-yellow sun with eight broadening rays that extend to the edges of the field.",
    },
  },
  {
    name: {
      en: "Northern Mariana Islands",
      ar: "جزر ماريانا الشمالية",
    },
    iso2: "MP",
    iso3: "MNP",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+1670",
    region: "Oceania",
    subregion: "Micronesia",
    flags: {
      png: "https://flagcdn.com/w320/mp.png",
      svg: "https://flagcdn.com/mp.svg",
    },
  },
  {
    name: {
      en: "Norway",
      ar: "النرويج",
    },
    iso2: "NO",
    iso3: "NOR",
    currency: {
      NOK: {
        name: "Norwegian krone",
        symbol: "kr",
      },
    },
    country_code: "+47",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/no.png",
      svg: "https://flagcdn.com/no.svg",
      alt: "The flag of Norway has a red field with a large white-edged navy blue cross that extends to the edges of the field. The vertical part of this cross is offset towards the hoist side.",
    },
  },
  {
    name: {
      en: "Oman",
      ar: "عمان",
    },
    iso2: "OM",
    iso3: "OMN",
    currency: {
      OMR: {
        name: "Omani rial",
        symbol: "ر.ع.",
      },
    },
    country_code: "+968",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/om.png",
      svg: "https://flagcdn.com/om.svg",
      alt: "The flag of Oman features a red vertical band on the hoist side that takes up about one-fourth the width of the field, and three equal horizontal bands of white, red and green adjoining the vertical band. At the top of the vertical band is the white emblem of Oman.",
    },
  },
  {
    name: {
      en: "Pakistan",
      ar: "باكستان",
    },
    iso2: "PK",
    iso3: "PAK",
    currency: {
      PKR: {
        name: "Pakistani rupee",
        symbol: "₨",
      },
    },
    country_code: "+92",
    region: "Asia",
    subregion: "Southern Asia",
    flags: {
      png: "https://flagcdn.com/w320/pk.png",
      svg: "https://flagcdn.com/pk.svg",
      alt: "The flag of Pakistan is composed of a white vertical band on its hoist side that takes up about one-fourth the width of the field and a dark green rectangular area that spans the rest of the field. A white fly-side facing crescent and five-pointed star are centered in the dark green area.",
    },
  },
  {
    name: {
      en: "Palau",
      ar: "بالاو",
    },
    iso2: "PW",
    iso3: "PLW",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+680",
    region: "Oceania",
    subregion: "Micronesia",
    flags: {
      png: "https://flagcdn.com/w320/pw.png",
      svg: "https://flagcdn.com/pw.svg",
      alt: "The flag of Palau has a light blue field with a large golden-yellow circle that is offset slightly towards the hoist side of center.",
    },
  },
  {
    name: {
      en: "Palestine",
      ar: "فلسطين",
    },
    iso2: "PS",
    iso3: "PSE",
    currency: {
      EGP: {
        name: "Egyptian pound",
        symbol: "E£",
      },
      ILS: {
        name: "Israeli new shekel",
        symbol: "₪",
      },
      JOD: {
        name: "Jordanian dinar",
        symbol: "JD",
      },
    },
    country_code: "+970",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/ps.png",
      svg: "https://flagcdn.com/ps.svg",
    },
  },
  {
    name: {
      en: "Panama",
      ar: "بنما",
    },
    iso2: "PA",
    iso3: "PAN",
    currency: {
      PAB: {
        name: "Panamanian balboa",
        symbol: "B/.",
      },
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+507",
    region: "Americas",
    subregion: "Central America",
    flags: {
      png: "https://flagcdn.com/w320/pa.png",
      svg: "https://flagcdn.com/pa.svg",
      alt: "The flag of Panama is composed of four equal rectangular areas — a white rectangular area with a blue five-pointed star at its center, a red rectangular area, a white rectangular area with a red five-pointed star at its center, and a blue rectangular area — in the upper hoist side, upper fly side, lower fly side and lower hoist side respectively.",
    },
  },
  {
    name: {
      en: "Papua New Guinea",
      ar: "بابوا غينيا الجديدة",
    },
    iso2: "PG",
    iso3: "PNG",
    currency: {
      PGK: {
        name: "Papua New Guinean kina",
        symbol: "K",
      },
    },
    country_code: "+675",
    region: "Oceania",
    subregion: "Melanesia",
    flags: {
      png: "https://flagcdn.com/w320/pg.png",
      svg: "https://flagcdn.com/pg.svg",
      alt: "The flag of Papua New Guinea is divided diagonally, from the upper hoist-side corner to the lower fly-side corner, into a lower black and an upper red triangle. On the hoist side of the lower black triangle is a representation of the Southern Cross constellation made up of one small and four larger five-pointed white stars. A golden Raggiana bird-of-paradise is situated on the fly side of the upper red triangle.",
    },
  },
  {
    name: {
      en: "Paraguay",
      ar: "باراغواي",
    },
    iso2: "PY",
    iso3: "PRY",
    currency: {
      PYG: {
        name: "Paraguayan guaraní",
        symbol: "₲",
      },
    },
    country_code: "+595",
    region: "Americas",
    subregion: "South America",
    flags: {
      png: "https://flagcdn.com/w320/py.png",
      svg: "https://flagcdn.com/py.svg",
      alt: "The flag of Paraguay features three equal horizontal bands of red, white and blue, with an emblem centered in the white band. On the obverse side of the flag depicted, this emblem is the national coat of arms.",
    },
  },
  {
    name: {
      en: "Peru",
      ar: "بيرو",
    },
    iso2: "PE",
    iso3: "PER",
    currency: {
      PEN: {
        name: "Peruvian sol",
        symbol: "S/ ",
      },
    },
    country_code: "+51",
    region: "Americas",
    subregion: "South America",
    flags: {
      png: "https://flagcdn.com/w320/pe.png",
      svg: "https://flagcdn.com/pe.svg",
      alt: "The flag of Peru is composed of three equal vertical bands of red, white and red, with the national emblem centered in the white band.",
    },
  },
  {
    name: {
      en: "Philippines",
      ar: "الفلبين",
    },
    iso2: "PH",
    iso3: "PHL",
    currency: {
      PHP: {
        name: "Philippine peso",
        symbol: "₱",
      },
    },
    country_code: "+63",
    region: "Asia",
    subregion: "South-Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/ph.png",
      svg: "https://flagcdn.com/ph.svg",
      alt: "The flag of Philippines is composed of two equal horizontal bands of blue and red, with a white equilateral triangle superimposed on the hoist side of the field. This triangle has its base on the hoist end, spans about two-fifth the width of the field and bears a central golden-yellow sun with eight rays and a five-pointed golden-yellow star at each vertex.",
    },
  },
  {
    name: {
      en: "Pitcairn Islands",
      ar: "جزر بيتكيرن",
    },
    iso2: "PN",
    iso3: "PCN",
    currency: {
      NZD: {
        name: "New Zealand dollar",
        symbol: "$",
      },
    },
    country_code: "+64",
    region: "Oceania",
    subregion: "Polynesia",
    flags: {
      png: "https://flagcdn.com/w320/pn.png",
      svg: "https://flagcdn.com/pn.svg",
    },
  },
  {
    name: {
      en: "Poland",
      ar: "بولندا",
    },
    iso2: "PL",
    iso3: "POL",
    currency: {
      PLN: {
        name: "Polish złoty",
        symbol: "zł",
      },
    },
    country_code: "+48",
    region: "Europe",
    subregion: "Central Europe",
    flags: {
      png: "https://flagcdn.com/w320/pl.png",
      svg: "https://flagcdn.com/pl.svg",
      alt: "The flag of Poland is composed of two equal horizontal bands of white and red.",
    },
  },
  {
    name: {
      en: "Portugal",
      ar: "البرتغال",
    },
    iso2: "PT",
    iso3: "PRT",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+351",
    region: "Europe",
    subregion: "Southern Europe",
    flags: {
      png: "https://flagcdn.com/w320/pt.png",
      svg: "https://flagcdn.com/pt.svg",
      alt: "The flag of Portugal is composed of two vertical bands of green and red in the ratio of 2:3, with the coat of arms of Portugal centered over the two-color boundary.",
    },
  },
  {
    name: {
      en: "Puerto Rico",
      ar: "بويرتوريكو",
    },
    iso2: "PR",
    iso3: "PRI",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+1787",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/pr.png",
      svg: "https://flagcdn.com/pr.svg",
    },
  },
  {
    name: {
      en: "Qatar",
      ar: "قطر",
    },
    iso2: "QA",
    iso3: "QAT",
    currency: {
      QAR: {
        name: "Qatari riyal",
        symbol: "ر.ق",
      },
    },
    country_code: "+974",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/qa.png",
      svg: "https://flagcdn.com/qa.svg",
      alt: "The flag of Qatar has a maroon field, on the hoist side of which is a white vertical band that spans about one-third the width of the field and is separated from the rest of the field by nine adjoining fly-side pointing white isosceles triangles that serve as a serrated line.",
    },
  },
  {
    name: {
      en: "Republic of the Congo",
      ar: "جمهورية الكونفو",
    },
    iso2: "CG",
    iso3: "COG",
    currency: {
      XAF: {
        name: "Central African CFA franc",
        symbol: "Fr",
      },
    },
    country_code: "+242",
    region: "Africa",
    subregion: "Middle Africa",
    flags: {
      png: "https://flagcdn.com/w320/cg.png",
      svg: "https://flagcdn.com/cg.svg",
      alt: "The flag of the Republic of the Congo features a yellow diagonal band that extends from the lower hoist-side corner to the upper fly-side corner of the field. Above and beneath this band are a green and red triangle respectively.",
    },
  },
  {
    name: {
      en: "Romania",
      ar: "رومانيا",
    },
    iso2: "RO",
    iso3: "ROU",
    currency: {
      RON: {
        name: "Romanian leu",
        symbol: "lei",
      },
    },
    country_code: "+40",
    region: "Europe",
    subregion: "Southeast Europe",
    flags: {
      png: "https://flagcdn.com/w320/ro.png",
      svg: "https://flagcdn.com/ro.svg",
      alt: "The flag of Romania is composed of three equal vertical bands of navy blue, yellow and red.",
    },
  },
  {
    name: {
      en: "Russia",
      ar: "روسيا",
    },
    iso2: "RU",
    iso3: "RUS",
    currency: {
      RUB: {
        name: "Russian ruble",
        symbol: "₽",
      },
    },
    country_code: "+73",
    region: "Europe",
    subregion: "Eastern Europe",
    flags: {
      png: "https://flagcdn.com/w320/ru.png",
      svg: "https://flagcdn.com/ru.svg",
      alt: "The flag of Russia is composed of three equal horizontal bands of white, blue and red.",
    },
  },
  {
    name: {
      en: "Rwanda",
      ar: "رواندا",
    },
    iso2: "RW",
    iso3: "RWA",
    currency: {
      RWF: {
        name: "Rwandan franc",
        symbol: "Fr",
      },
    },
    country_code: "+250",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/rw.png",
      svg: "https://flagcdn.com/rw.svg",
      alt: "The flag of Rwanda is composed of three horizontal bands of light blue, yellow and green. The light blue band is twice the height of the other two bands and bears a yellow sun with twenty-four rays on its fly side.",
    },
  },
  {
    name: {
      en: "Réunion",
      ar: "لا ريونيون",
    },
    iso2: "RE",
    iso3: "REU",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+262",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/re.png",
      svg: "https://flagcdn.com/re.svg",
    },
  },
  {
    name: {
      en: "Saint Barthélemy",
      ar: "سان بارتليمي",
    },
    iso2: "BL",
    iso3: "BLM",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+590",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/bl.png",
      svg: "https://flagcdn.com/bl.svg",
    },
  },
  {
    name: {
      en: "Saint Helena, Ascension and Tristan da Cunha",
      ar: "سانت هيلينا وأسينشين وتريستان دا كونا",
    },
    iso2: "SH",
    iso3: "SHN",
    currency: {
      GBP: {
        name: "Pound sterling",
        symbol: "£",
      },
      SHP: {
        name: "Saint Helena pound",
        symbol: "£",
      },
    },
    country_code: "+290",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/sh.png",
      svg: "https://flagcdn.com/sh.svg",
    },
  },
  {
    name: {
      en: "Saint Kitts and Nevis",
      ar: "سانت كيتس ونيفيس",
    },
    iso2: "KN",
    iso3: "KNA",
    currency: {
      XCD: {
        name: "Eastern Caribbean dollar",
        symbol: "$",
      },
    },
    country_code: "+1869",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/kn.png",
      svg: "https://flagcdn.com/kn.svg",
      alt: "The flag of Saint Kitts and Nevis features two large five-pointed white stars within a yellow-edged black diagonal band that extends from the lower hoist-side corner to the upper fly-side corner of the field. Above and beneath this band are a green and red triangle respectively.",
    },
  },
  {
    name: {
      en: "Saint Lucia",
      ar: "سانت لوسيا",
    },
    iso2: "LC",
    iso3: "LCA",
    currency: {
      XCD: {
        name: "Eastern Caribbean dollar",
        symbol: "$",
      },
    },
    country_code: "+1758",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/lc.png",
      svg: "https://flagcdn.com/lc.svg",
      alt: "The flag of Saint Lucia has a light blue field, at the center of which are two triangles which share a common base — a small golden-yellow isosceles triangle superimposed on a large white-edged black isosceles triangle.",
    },
  },
  {
    name: {
      en: "Saint Martin",
      ar: "سانت مارتن",
    },
    iso2: "MF",
    iso3: "MAF",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+590",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/mf.png",
      svg: "https://flagcdn.com/mf.svg",
    },
  },
  {
    name: {
      en: "Saint Pierre and Miquelon",
      ar: "سان بيير وميكلون",
    },
    iso2: "PM",
    iso3: "SPM",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+508",
    region: "Americas",
    subregion: "North America",
    flags: {
      png: "https://flagcdn.com/w320/pm.png",
      svg: "https://flagcdn.com/pm.svg",
    },
  },
  {
    name: {
      en: "Saint Vincent and the Grenadines",
      ar: "سانت فينسنت والغرينادين",
    },
    iso2: "VC",
    iso3: "VCT",
    currency: {
      XCD: {
        name: "Eastern Caribbean dollar",
        symbol: "$",
      },
    },
    country_code: "+1784",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/vc.png",
      svg: "https://flagcdn.com/vc.svg",
      alt: "The flag of Saint Vincent and the Grenadines is composed of three vertical bands of blue, gold and green. The gold band is twice as wide as the other two bands and bears three green diamonds arranged to form the letter V at its center.",
    },
  },
  {
    name: {
      en: "Samoa",
      ar: "ساموا",
    },
    iso2: "WS",
    iso3: "WSM",
    currency: {
      WST: {
        name: "Samoan tālā",
        symbol: "T",
      },
    },
    country_code: "+685",
    region: "Oceania",
    subregion: "Polynesia",
    flags: {
      png: "https://flagcdn.com/w320/ws.png",
      svg: "https://flagcdn.com/ws.svg",
      alt: "The flag of Samoa has a red field. A blue rectangle, bearing a representation of the Southern Cross made up of five large and one smaller five-pointed white stars, is superimposed in the canton.",
    },
  },
  {
    name: {
      en: "San Marino",
      ar: "سان مارينو",
    },
    iso2: "SM",
    iso3: "SMR",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+378",
    region: "Europe",
    subregion: "Southern Europe",
    flags: {
      png: "https://flagcdn.com/w320/sm.png",
      svg: "https://flagcdn.com/sm.svg",
      alt: "The flag of San Marino is composed of two equal horizontal bands of white and light blue, with the national coat of arms superimposed in the center.",
    },
  },
  {
    name: {
      en: "Senegal",
      ar: "السنغال",
    },
    iso2: "SN",
    iso3: "SEN",
    currency: {
      XOF: {
        name: "West African CFA franc",
        symbol: "Fr",
      },
    },
    country_code: "+221",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/sn.png",
      svg: "https://flagcdn.com/sn.svg",
      alt: "The flag of Senegal is composed of three equal vertical bands of green, golden-yellow and red, with a five-pointed green star centered in the golden-yellow band.",
    },
  },
  {
    name: {
      en: "Serbia",
      ar: "صيربيا",
    },
    iso2: "RS",
    iso3: "SRB",
    currency: {
      RSD: {
        name: "Serbian dinar",
        symbol: "дин.",
      },
    },
    country_code: "+381",
    region: "Europe",
    subregion: "Southeast Europe",
    flags: {
      png: "https://flagcdn.com/w320/rs.png",
      svg: "https://flagcdn.com/rs.svg",
      alt: "The flag of Serbia is composed of three equal horizontal bands of red, blue and white. The coat of arms of Serbia is superimposed at the center of the field slightly towards the hoist side.",
    },
  },
  {
    name: {
      en: "Seychelles",
      ar: "سيشل",
    },
    iso2: "SC",
    iso3: "SYC",
    currency: {
      SCR: {
        name: "Seychellois rupee",
        symbol: "₨",
      },
    },
    country_code: "+248",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/sc.png",
      svg: "https://flagcdn.com/sc.svg",
      alt: "The flag of Seychelles is composed of five broadening oblique bands of blue, yellow, red, white and green, which extend from the hoist side of the bottom edge to the top and fly edges of the field.",
    },
  },
  {
    name: {
      en: "Sierra Leone",
      ar: "سيراليون",
    },
    iso2: "SL",
    iso3: "SLE",
    currency: {
      SLL: {
        name: "Sierra Leonean leone",
        symbol: "Le",
      },
    },
    country_code: "+232",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/sl.png",
      svg: "https://flagcdn.com/sl.svg",
      alt: "The flag of Sierra Leone is composed of three equal horizontal bands of green, white and blue.",
    },
  },
  {
    name: {
      en: "Singapore",
      ar: "سنغافورة",
    },
    iso2: "SG",
    iso3: "SGP",
    currency: {
      SGD: {
        name: "Singapore dollar",
        symbol: "$",
      },
    },
    country_code: "+65",
    region: "Asia",
    subregion: "South-Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/sg.png",
      svg: "https://flagcdn.com/sg.svg",
      alt: "The flag of Singapore is composed of two equal horizontal bands of red and white. On the hoist side of the red band is a fly-side facing white crescent which partially encloses five small five-pointed white stars arranged in the shape of a pentagon.",
    },
  },
  {
    name: {
      en: "Sint Maarten",
      ar: "سينت مارتن",
    },
    iso2: "SX",
    iso3: "SXM",
    currency: {
      ANG: {
        name: "Netherlands Antillean guilder",
        symbol: "ƒ",
      },
    },
    country_code: "+1721",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/sx.png",
      svg: "https://flagcdn.com/sx.svg",
    },
  },
  {
    name: {
      en: "Slovakia",
      ar: "سلوفاكيا",
    },
    iso2: "SK",
    iso3: "SVK",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+421",
    region: "Europe",
    subregion: "Central Europe",
    flags: {
      png: "https://flagcdn.com/w320/sk.png",
      svg: "https://flagcdn.com/sk.svg",
      alt: "The flag of Slovakia is composed of three equal horizontal bands of white, blue and red. The coat of arms of Slovakia is superimposed at the center of the field slightly towards the hoist side.",
    },
  },
  {
    name: {
      en: "Slovenia",
      ar: "سلوفينيا",
    },
    iso2: "SI",
    iso3: "SVN",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+386",
    region: "Europe",
    subregion: "Central Europe",
    flags: {
      png: "https://flagcdn.com/w320/si.png",
      svg: "https://flagcdn.com/si.svg",
      alt: "The flag of Slovenia is composed of three equal horizontal bands of white, blue and red. The national coat of arms is situated in the upper hoist side of the field centered on the boundary between the white and blue bands.",
    },
  },
  {
    name: {
      en: "Solomon Islands",
      ar: "جزر سليمان",
    },
    iso2: "SB",
    iso3: "SLB",
    currency: {
      SBD: {
        name: "Solomon Islands dollar",
        symbol: "$",
      },
    },
    country_code: "+677",
    region: "Oceania",
    subregion: "Melanesia",
    flags: {
      png: "https://flagcdn.com/w320/sb.png",
      svg: "https://flagcdn.com/sb.svg",
      alt: "The flag of Solomon Islands features a thin yellow diagonal band that extends from the lower hoist-side corner to the upper fly-side corner of the field. Above and beneath this band are a blue and green triangle respectively. Five white five-pointed stars arranged in an X shape are situated on the hoist side of the upper blue triangle.",
    },
  },
  {
    name: {
      en: "Somalia",
      ar: "الصومال",
    },
    iso2: "SO",
    iso3: "SOM",
    currency: {
      SOS: {
        name: "Somali shilling",
        symbol: "Sh",
      },
    },
    country_code: "+252",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/so.png",
      svg: "https://flagcdn.com/so.svg",
      alt: "The flag of Somalia features a large five-pointed white star centered on a light blue field.",
    },
  },
  {
    name: {
      en: "South Africa",
      ar: "جنوب أفريقيا",
    },
    iso2: "ZA",
    iso3: "ZAF",
    currency: {
      ZAR: {
        name: "South African rand",
        symbol: "R",
      },
    },
    country_code: "+27",
    region: "Africa",
    subregion: "Southern Africa",
    flags: {
      png: "https://flagcdn.com/w320/za.png",
      svg: "https://flagcdn.com/za.svg",
      alt: "The flag of South Africa is composed of two equal horizontal bands of red and blue, with a yellow-edged black isosceles triangle superimposed on the hoist side of the field. This triangle has its base centered on the hoist end, spans about two-fifth the width and two-third the height of the field, and is enclosed on its sides by the arms of a white-edged green horizontally oriented Y-shaped band which extends along the boundary of the red and blue bands to the fly end of the field.",
    },
  },
  {
    name: {
      en: "South Georgia",
      ar: "جورجيا الجنوبية",
    },
    iso2: "GS",
    iso3: "SGS",
    currency: {
      SHP: {
        name: "Saint Helena pound",
        symbol: "£",
      },
    },
    country_code: "+500",
    region: "Antarctic",
    flags: {
      png: "https://flagcdn.com/w320/gs.png",
      svg: "https://flagcdn.com/gs.svg",
    },
  },
  {
    name: {
      en: "South Korea",
      ar: "كوريا الجنوبية",
    },
    iso2: "KR",
    iso3: "KOR",
    currency: {
      KRW: {
        name: "South Korean won",
        symbol: "₩",
      },
    },
    country_code: "+82",
    region: "Asia",
    subregion: "Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/kr.png",
      svg: "https://flagcdn.com/kr.svg",
      alt: "The flag of South Korea has a white field, at the center of which is a red and blue Taegeuk circle surrounded by four black trigrams, one in each corner.",
    },
  },
  {
    name: {
      en: "South Sudan",
      ar: "جنوب السودان",
    },
    iso2: "SS",
    iso3: "SSD",
    currency: {
      SSP: {
        name: "South Sudanese pound",
        symbol: "£",
      },
    },
    country_code: "+211",
    region: "Africa",
    subregion: "Middle Africa",
    flags: {
      png: "https://flagcdn.com/w320/ss.png",
      svg: "https://flagcdn.com/ss.svg",
      alt: "The flag of South Sudan is composed of three equal horizontal bands of black, red with white top and bottom edges, and green. A blue equilateral triangle which spans about two-fifth the width of the field is superimposed on the hoist side with its base on the hoist end of the field. At the center of this triangle is a five-pointed yellow star.",
    },
  },
  {
    name: {
      en: "Spain",
      ar: "إسبانيا",
    },
    iso2: "ES",
    iso3: "ESP",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+34",
    region: "Europe",
    subregion: "Southern Europe",
    flags: {
      png: "https://flagcdn.com/w320/es.png",
      svg: "https://flagcdn.com/es.svg",
      alt: "The flag of Spain is composed of three horizontal bands of red, yellow and red, with the yellow band twice the height of the red bands. In the yellow band is the national coat of arms offset slightly towards the hoist side of center.",
    },
  },
  {
    name: {
      en: "Sri Lanka",
      ar: "سريلانكا",
    },
    iso2: "LK",
    iso3: "LKA",
    currency: {
      LKR: {
        name: "Sri Lankan rupee",
        symbol: "Rs  රු",
      },
    },
    country_code: "+94",
    region: "Asia",
    subregion: "Southern Asia",
    flags: {
      png: "https://flagcdn.com/w320/lk.png",
      svg: "https://flagcdn.com/lk.svg",
      alt: "The flag of Sri Lanka features two large adjacent but separate rectangular areas, centered on a golden-yellow field. The smaller hoist-side rectangle is divided into two equal vertical bands of teal and orange, and the larger fly-side rectangle is maroon with a centered golden-yellow lion holding a Kastane sword in its right fore-paw and four golden-yellow Bo leaves, one in each corner.",
    },
  },
  {
    name: {
      en: "Sudan",
      ar: "السودان",
    },
    iso2: "SD",
    iso3: "SDN",
    currency: {
      SDG: {
        name: "Sudanese pound",
      },
    },
    country_code: "+249",
    region: "Africa",
    subregion: "Northern Africa",
    flags: {
      png: "https://flagcdn.com/w320/sd.png",
      svg: "https://flagcdn.com/sd.svg",
      alt: "The flag of Sudan is composed of three equal horizontal bands of red, white and black, with a green isosceles triangle superimposed on the hoist side. The green triangle spans about two-fifth the width of the field with its base on the hoist end.",
    },
  },
  {
    name: {
      en: "Suriname",
      ar: "سورينام",
    },
    iso2: "SR",
    iso3: "SUR",
    currency: {
      SRD: {
        name: "Surinamese dollar",
        symbol: "$",
      },
    },
    country_code: "+597",
    region: "Americas",
    subregion: "South America",
    flags: {
      png: "https://flagcdn.com/w320/sr.png",
      svg: "https://flagcdn.com/sr.svg",
      alt: "The flag of Suriname is composed of five horizontal bands of green, white, red, white and green in the ratio of 2:1:4:1:2. A large five-pointed yellow star is centered in the red band.",
    },
  },
  {
    name: {
      en: "Svalbard and Jan Mayen",
      ar: "سفالبارد ويان ماين",
    },
    iso2: "SJ",
    iso3: "SJM",
    currency: {
      NOK: {
        name: "krone",
        symbol: "kr",
      },
    },
    country_code: "+4779",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/sj.png",
      svg: "https://flagcdn.com/sj.svg",
    },
  },
  {
    name: {
      en: "Sweden",
      ar: "السويد",
    },
    iso2: "SE",
    iso3: "SWE",
    currency: {
      SEK: {
        name: "Swedish krona",
        symbol: "kr",
      },
    },
    country_code: "+46",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/se.png",
      svg: "https://flagcdn.com/se.svg",
      alt: "The flag of Sweden has a blue field with a large golden-yellow cross that extend to the edges of the field. The vertical part of this cross is offset towards the hoist side.",
    },
  },
  {
    name: {
      en: "Switzerland",
      ar: "سويسرا",
    },
    iso2: "CH",
    iso3: "CHE",
    currency: {
      CHF: {
        name: "Swiss franc",
        symbol: "Fr.",
      },
    },
    country_code: "+41",
    region: "Europe",
    subregion: "Western Europe",
    flags: {
      png: "https://flagcdn.com/w320/ch.png",
      svg: "https://flagcdn.com/ch.svg",
      alt: "The flag of Switzerland is square shaped. It features a white Swiss cross centered on a red field.",
    },
  },
  {
    name: {
      en: "Syria",
      ar: "سوريا",
    },
    iso2: "SY",
    iso3: "SYR",
    currency: {
      SYP: {
        name: "Syrian pound",
        symbol: "£",
      },
    },
    country_code: "+963",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/sy.png",
      svg: "https://flagcdn.com/sy.svg",
      alt: "The flag of Syria is composed of three equal horizontal bands of red, white and black. At the center of the white band are two small five-pointed green stars arranged in a horizontal line.",
    },
  },
  {
    name: {
      en: "São Tomé and Príncipe",
      ar: "ساو تومي وبرينسيب",
    },
    iso2: "ST",
    iso3: "STP",
    currency: {
      STN: {
        name: "São Tomé and Príncipe dobra",
        symbol: "Db",
      },
    },
    country_code: "+239",
    region: "Africa",
    subregion: "Middle Africa",
    flags: {
      png: "https://flagcdn.com/w320/st.png",
      svg: "https://flagcdn.com/st.svg",
      alt: "The flag of South Sudan is composed of three equal horizontal bands of black, red with white top and bottom edges, and green. A blue equilateral triangle which spans about two-fifth the width of the field is superimposed on the hoist side with its base on the hoist end of the field. At the center of this triangle is a five-pointed yellow star.",
    },
  },
  {
    name: {
      en: "Taiwan",
      ar: "تايوان",
    },
    iso2: "TW",
    iso3: "TWN",
    currency: {
      TWD: {
        name: "New Taiwan dollar",
        symbol: "$",
      },
    },
    country_code: "+886",
    region: "Asia",
    subregion: "Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/tw.png",
      svg: "https://flagcdn.com/tw.svg",
    },
  },
  {
    name: {
      en: "Tajikistan",
      ar: "طاجيكستان",
    },
    iso2: "TJ",
    iso3: "TJK",
    currency: {
      TJS: {
        name: "Tajikistani somoni",
        symbol: "ЅМ",
      },
    },
    country_code: "+992",
    region: "Asia",
    subregion: "Central Asia",
    flags: {
      png: "https://flagcdn.com/w320/tj.png",
      svg: "https://flagcdn.com/tj.svg",
      alt: "The flag of Tajikistan is composed of three horizontal bands of red, white and green in the ratio of 2:3:2. A golden-yellow crown surmounted by an arc of seven five-pointed golden-yellow stars is centered in the white band.",
    },
  },
  {
    name: {
      en: "Tanzania",
      ar: "تنزانيا",
    },
    iso2: "TZ",
    iso3: "TZA",
    currency: {
      TZS: {
        name: "Tanzanian shilling",
        symbol: "Sh",
      },
    },
    country_code: "+255",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/tz.png",
      svg: "https://flagcdn.com/tz.svg",
      alt: "The flag of Tanzania features a yellow-edged black diagonal band that extends from the lower hoist-side corner to the upper fly-side corner of the field. Above and beneath this band are a green and light blue triangle respectively.",
    },
  },
  {
    name: {
      en: "Thailand",
      ar: "تايلند",
    },
    iso2: "TH",
    iso3: "THA",
    currency: {
      THB: {
        name: "Thai baht",
        symbol: "฿",
      },
    },
    country_code: "+66",
    region: "Asia",
    subregion: "South-Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/th.png",
      svg: "https://flagcdn.com/th.svg",
      alt: "The flag of Thailand is composed of five horizontal bands of red, white, blue, white and red, with the central blue band twice the height of the other four bands.",
    },
  },
  {
    name: {
      en: "Timor-Leste",
      ar: "تيمور الشرقية",
    },
    iso2: "TL",
    iso3: "TLS",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+670",
    region: "Asia",
    subregion: "South-Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/tl.png",
      svg: "https://flagcdn.com/tl.svg",
      alt: "The flag of Timor-Leste has a red field with two isosceles triangles which share a common base on the hoist end. The smaller black triangle, which bears a five-pointed white star at its center and spans one-third the width of the field, is superimposed on the larger yellow triangle that extends to the center of the field.",
    },
  },
  {
    name: {
      en: "Togo",
      ar: "توغو",
    },
    iso2: "TG",
    iso3: "TGO",
    currency: {
      XOF: {
        name: "West African CFA franc",
        symbol: "Fr",
      },
    },
    country_code: "+228",
    region: "Africa",
    subregion: "Western Africa",
    flags: {
      png: "https://flagcdn.com/w320/tg.png",
      svg: "https://flagcdn.com/tg.svg",
      alt: "The flag of Togo is composed of five equal horizontal bands of green alternating with yellow. A red square bearing a five-pointed white star is superimposed in the canton.",
    },
  },
  {
    name: {
      en: "Tokelau",
      ar: "توكيلاو",
    },
    iso2: "TK",
    iso3: "TKL",
    currency: {
      NZD: {
        name: "New Zealand dollar",
        symbol: "$",
      },
    },
    country_code: "+690",
    region: "Oceania",
    subregion: "Polynesia",
    flags: {
      png: "https://flagcdn.com/w320/tk.png",
      svg: "https://flagcdn.com/tk.svg",
    },
  },
  {
    name: {
      en: "Tonga",
      ar: "تونغا",
    },
    iso2: "TO",
    iso3: "TON",
    currency: {
      TOP: {
        name: "Tongan paʻanga",
        symbol: "T$",
      },
    },
    country_code: "+676",
    region: "Oceania",
    subregion: "Polynesia",
    flags: {
      png: "https://flagcdn.com/w320/to.png",
      svg: "https://flagcdn.com/to.svg",
      alt: "The flag of Tonga has a red field. A white rectangle bearing a red Greek cross is superimposed in the canton.",
    },
  },
  {
    name: {
      en: "Trinidad and Tobago",
      ar: "ترينيداد وتوباغو",
    },
    iso2: "TT",
    iso3: "TTO",
    currency: {
      TTD: {
        name: "Trinidad and Tobago dollar",
        symbol: "$",
      },
    },
    country_code: "+1868",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/tt.png",
      svg: "https://flagcdn.com/tt.svg",
      alt: "The flag of Trinidad and Tobago has a red field with a white-edged black diagonal band that extends from the upper hoist-side corner to the lower fly-side corner of the field.",
    },
  },
  {
    name: {
      en: "Tunisia",
      ar: "تونس",
    },
    iso2: "TN",
    iso3: "TUN",
    currency: {
      TND: {
        name: "Tunisian dinar",
        symbol: "د.ت",
      },
    },
    country_code: "+216",
    region: "Africa",
    subregion: "Northern Africa",
    flags: {
      png: "https://flagcdn.com/w320/tn.png",
      svg: "https://flagcdn.com/tn.svg",
      alt: "The flag of Tunisia has a red field. A white circle bearing a five-pointed red star within a fly-side facing red crescent is situated at the center of the field.",
    },
  },
  {
    name: {
      en: "Turkey",
      ar: "تركيا",
    },
    iso2: "TR",
    iso3: "TUR",
    currency: {
      TRY: {
        name: "Turkish lira",
        symbol: "₺",
      },
    },
    country_code: "+90",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/tr.png",
      svg: "https://flagcdn.com/tr.svg",
      alt: "The flag of Turkey has a red field bearing a large fly-side facing white crescent and a smaller five-pointed white star placed just outside the crescent opening. The white crescent and star are offset slightly towards the hoist side of center.",
    },
  },
  {
    name: {
      en: "Turkmenistan",
      ar: "تركمانستان",
    },
    iso2: "TM",
    iso3: "TKM",
    currency: {
      TMT: {
        name: "Turkmenistan manat",
        symbol: "m",
      },
    },
    country_code: "+993",
    region: "Asia",
    subregion: "Central Asia",
    flags: {
      png: "https://flagcdn.com/w320/tm.png",
      svg: "https://flagcdn.com/tm.svg",
      alt: "The flag of Turkmenistan has a green field. It features a red vertical band, bearing five carpet guls stacked above two crossed olive branches, near the hoist end of the field. Just to the fly side of the vertical band near the top edge of the field is a hoist-side facing white crescent and five small five-pointed white stars placed just outside the crescent opening.",
    },
  },
  {
    name: {
      en: "Turks and Caicos Islands",
      ar: "جزر توركس وكايكوس",
    },
    iso2: "TC",
    iso3: "TCA",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+1649",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/tc.png",
      svg: "https://flagcdn.com/tc.svg",
    },
  },
  {
    name: {
      en: "Tuvalu",
      ar: "توفالو",
    },
    iso2: "TV",
    iso3: "TUV",
    currency: {
      AUD: {
        name: "Australian dollar",
        symbol: "$",
      },
      TVD: {
        name: "Tuvaluan dollar",
        symbol: "$",
      },
    },
    country_code: "+688",
    region: "Oceania",
    subregion: "Polynesia",
    flags: {
      png: "https://flagcdn.com/w320/tv.png",
      svg: "https://flagcdn.com/tv.svg",
      alt: "The flag of Tuvalu has a light blue field with the flag of the United Kingdom — the Union Jack — in the canton. A representation of the country's nine Islands using nine five-pointed yellow stars is situated in the fly half of the field.",
    },
  },
  {
    name: {
      en: "Uganda",
      ar: "أوغندا",
    },
    iso2: "UG",
    iso3: "UGA",
    currency: {
      UGX: {
        name: "Ugandan shilling",
        symbol: "Sh",
      },
    },
    country_code: "+256",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/ug.png",
      svg: "https://flagcdn.com/ug.svg",
      alt: "The flag of Uganda is composed of six equal horizontal bands of black, yellow, red, black, yellow and red. A white circle bearing a hoist-side facing grey red-crested crane is superimposed in the center of the field.",
    },
  },
  {
    name: {
      en: "Ukraine",
      ar: "أوكرانيا",
    },
    iso2: "UA",
    iso3: "UKR",
    currency: {
      UAH: {
        name: "Ukrainian hryvnia",
        symbol: "₴",
      },
    },
    country_code: "+380",
    region: "Europe",
    subregion: "Eastern Europe",
    flags: {
      png: "https://flagcdn.com/w320/ua.png",
      svg: "https://flagcdn.com/ua.svg",
      alt: "The flag of Ukraine is composed of two equal horizontal bands of blue and yellow.",
    },
  },
  {
    name: {
      en: "United Arab Emirates",
      ar: "دولة الإمارات العربية المتحدة",
    },
    iso2: "AE",
    iso3: "ARE",
    currency: {
      AED: {
        name: "United Arab Emirates dirham",
        symbol: "د.إ",
      },
    },
    country_code: "+971",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/ae.png",
      svg: "https://flagcdn.com/ae.svg",
      alt: "The flag of United Arab Emirates features a red vertical band on its hoist side that takes up about one-fourth the width of the field and three equal horizontal bands of green, white and black adjoining the vertical band.",
    },
  },
  {
    name: {
      en: "United Kingdom",
      ar: "المملكة المتحدة",
    },
    iso2: "GB",
    iso3: "GBR",
    currency: {
      GBP: {
        name: "British pound",
        symbol: "£",
      },
    },
    country_code: "+44",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/gb.png",
      svg: "https://flagcdn.com/gb.svg",
      alt: "The flag of the United Kingdom — the Union Jack — has a blue field. It features the white-edged red cross of Saint George superimposed on the diagonal red cross of Saint Patrick which is superimposed on the diagonal white cross of Saint Andrew.",
    },
  },
  {
    name: {
      en: "United States",
      ar: "الولايات المتحدة",
    },
    iso2: "US",
    iso3: "USA",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+1201",
    region: "Americas",
    subregion: "North America",
    flags: {
      png: "https://flagcdn.com/w320/us.png",
      svg: "https://flagcdn.com/us.svg",
      alt: "The flag of the United States of America is composed of thirteen equal horizontal bands of red alternating with white. A blue rectangle, bearing fifty small five-pointed white stars arranged in nine rows where rows of six stars alternate with rows of five stars, is superimposed in the canton.",
    },
  },
  {
    name: {
      en: "United States Minor Outlying Islands",
      ar: "جزر الولايات المتحدة الصغيرة النائية",
    },
    iso2: "UM",
    iso3: "UMI",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+268",
    region: "Americas",
    subregion: "North America",
    flags: {
      png: "https://flagcdn.com/w320/um.png",
      svg: "https://flagcdn.com/um.svg",
    },
  },
  {
    name: {
      en: "United States Virgin Islands",
      ar: "جزر العذراء الامريكية",
    },
    iso2: "VI",
    iso3: "VIR",
    currency: {
      USD: {
        name: "United States dollar",
        symbol: "$",
      },
    },
    country_code: "+1340",
    region: "Americas",
    subregion: "Caribbean",
    flags: {
      png: "https://flagcdn.com/w320/vi.png",
      svg: "https://flagcdn.com/vi.svg",
    },
  },
  {
    name: {
      en: "Uruguay",
      ar: "الأوروغواي",
    },
    iso2: "UY",
    iso3: "URY",
    currency: {
      UYU: {
        name: "Uruguayan peso",
        symbol: "$",
      },
    },
    country_code: "+598",
    region: "Americas",
    subregion: "South America",
    flags: {
      png: "https://flagcdn.com/w320/uy.png",
      svg: "https://flagcdn.com/uy.svg",
      alt: "The flag of Uruguay is composed of nine equal horizontal bands of white alternating with blue, with a white square superimposed in the canton. In the white square is a yellow sun bearing a human face — the Sun of May — from which sixteen rays extend. The sun's rays alternate between triangular and wavy.",
    },
  },
  {
    name: {
      en: "Uzbekistan",
      ar: "أوزباكستان",
    },
    iso2: "UZ",
    iso3: "UZB",
    currency: {
      UZS: {
        name: "Uzbekistani soʻm",
        symbol: "so'm",
      },
    },
    country_code: "+998",
    region: "Asia",
    subregion: "Central Asia",
    flags: {
      png: "https://flagcdn.com/w320/uz.png",
      svg: "https://flagcdn.com/uz.svg",
      alt: "The flag of Uzbekistan is composed of three equal horizontal bands of turquoise, white with red top and bottom edges, and green. On the hoist side of the turquoise band is a fly-side facing white crescent and twelve five-pointed white stars arranged just outside the crescent opening in three rows comprising three, four and five stars.",
    },
  },
  {
    name: {
      en: "Vanuatu",
      ar: "فانواتو",
    },
    iso2: "VU",
    iso3: "VUT",
    currency: {
      VUV: {
        name: "Vanuatu vatu",
        symbol: "Vt",
      },
    },
    country_code: "+678",
    region: "Oceania",
    subregion: "Melanesia",
    flags: {
      png: "https://flagcdn.com/w320/vu.png",
      svg: "https://flagcdn.com/vu.svg",
      alt: "The flag of Vanuatu is composed of two equal horizontal bands of red and green, with a black isosceles triangle superimposed on the hoist side of the field. This triangle has its base on the hoist end, spans about two-fifth the width of the field and is enclosed on its sides by the arms of a thin black-edged yellow horizontally oriented Y-shaped band which extends along the boundary of the red and green bands to the fly end of the field. A yellow boar's tusk encircling two yellow crossed namele leaves is centered in the triangle.",
    },
  },
  {
    name: {
      en: "Vatican City",
      ar: "مدينة الفاتيكان",
    },
    iso2: "VA",
    iso3: "VAT",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+3906698",
    region: "Europe",
    subregion: "Southern Europe",
    flags: {
      png: "https://flagcdn.com/w320/va.png",
      svg: "https://flagcdn.com/va.svg",
      alt: "The flag of Vatican City is square shaped. It is composed of two equal vertical bands of yellow and white, with national coat of arms centered in the white band. The national coat of arms comprises the Papal Tiara superimposed on two crossed keys.",
    },
  },
  {
    name: {
      en: "Venezuela",
      ar: "فنزويلا",
    },
    iso2: "VE",
    iso3: "VEN",
    currency: {
      VES: {
        name: "Venezuelan bolívar soberano",
        symbol: "Bs.S.",
      },
    },
    country_code: "+58",
    region: "Americas",
    subregion: "South America",
    flags: {
      png: "https://flagcdn.com/w320/ve.png",
      svg: "https://flagcdn.com/ve.svg",
      alt: "The flag of Venezuela is composed of three equal horizontal bands of yellow, blue and red. At the center of the blue band are eight five-pointed white stars arranged in a horizontal arc.",
    },
  },
  {
    name: {
      en: "Vietnam",
      ar: "فيتنام",
    },
    iso2: "VN",
    iso3: "VNM",
    currency: {
      VND: {
        name: "Vietnamese đồng",
        symbol: "₫",
      },
    },
    country_code: "+84",
    region: "Asia",
    subregion: "South-Eastern Asia",
    flags: {
      png: "https://flagcdn.com/w320/vn.png",
      svg: "https://flagcdn.com/vn.svg",
      alt: "The flag of Vietnam features a large five-pointed yellow star on a red field.",
    },
  },
  {
    name: {
      en: "Wallis and Futuna",
      ar: "واليس وفوتونا",
    },
    iso2: "WF",
    iso3: "WLF",
    currency: {
      XPF: {
        name: "CFP franc",
        symbol: "₣",
      },
    },
    country_code: "+681",
    region: "Oceania",
    subregion: "Polynesia",
    flags: {
      png: "https://flagcdn.com/w320/wf.png",
      svg: "https://flagcdn.com/wf.svg",
    },
  },
  {
    name: {
      en: "Western Sahara",
      ar: "الصحراء الغربية",
    },
    iso2: "EH",
    iso3: "ESH",
    currency: {
      DZD: {
        name: "Algerian dinar",
        symbol: "دج",
      },
      MAD: {
        name: "Moroccan dirham",
        symbol: "DH",
      },
      MRU: {
        name: "Mauritanian ouguiya",
        symbol: "UM",
      },
    },
    country_code: "+2125288",
    region: "Africa",
    subregion: "Northern Africa",
    flags: {
      png: "https://flagcdn.com/w320/eh.png",
      svg: "https://flagcdn.com/eh.svg",
    },
  },
  {
    name: {
      en: "Yemen",
      ar: "اليمن",
    },
    iso2: "YE",
    iso3: "YEM",
    currency: {
      YER: {
        name: "Yemeni rial",
        symbol: "﷼",
      },
    },
    country_code: "+967",
    region: "Asia",
    subregion: "Western Asia",
    flags: {
      png: "https://flagcdn.com/w320/ye.png",
      svg: "https://flagcdn.com/ye.svg",
      alt: "The flag of Yemen is composed of three equal horizontal bands of red, white and black.",
    },
  },
  {
    name: {
      en: "Zambia",
      ar: "زامبيا",
    },
    iso2: "ZM",
    iso3: "ZMB",
    currency: {
      ZMW: {
        name: "Zambian kwacha",
        symbol: "ZK",
      },
    },
    country_code: "+260",
    region: "Africa",
    subregion: "Eastern Africa",
    flags: {
      png: "https://flagcdn.com/w320/zm.png",
      svg: "https://flagcdn.com/zm.svg",
      alt: "The flag of Zambia has a green field, on the fly side of which is a soaring orange African fish eagle above a rectangular area divided into three equal vertical bands of red, black and orange.",
    },
  },
  {
    name: {
      en: "Zimbabwe",
      ar: "زيمبابوي",
    },
    iso2: "ZW",
    iso3: "ZWE",
    currency: {
      ZWL: {
        name: "Zimbabwean dollar",
        symbol: "$",
      },
    },
    country_code: "+263",
    region: "Africa",
    subregion: "Southern Africa",
    flags: {
      png: "https://flagcdn.com/w320/zw.png",
      svg: "https://flagcdn.com/zw.svg",
      alt: "The flag of Zimbabwe is composed of seven equal horizontal bands of green, yellow, red, black, red, yellow and green, with a white isosceles triangle superimposed on the hoist side of the field. This triangle is edged in black, spans about one-fourth the width of the field and has its base on the hoist end. A yellow Zimbabwe bird superimposed on a five-pointed red star is centered in the triangle.",
    },
  },
  {
    name: {
      en: "Åland Islands",
      ar: "جزر أولاند",
    },
    iso2: "AX",
    iso3: "ALA",
    currency: {
      EUR: {
        name: "Euro",
        symbol: "€",
      },
    },
    country_code: "+35818",
    region: "Europe",
    subregion: "Northern Europe",
    flags: {
      png: "https://flagcdn.com/w320/ax.png",
      svg: "https://flagcdn.com/ax.svg",
    },
  },
];
